<style lang="scss">
#backend {
  $height-header: 70px;

  header {
    height: 0;
    //border:1px solid red;
    @media (max-width: 1200px) {
      height: $height-header;
    }
    @media (max-width: $width-xl) {
      border-bottom: 1px solid #eee;
      position: fixed;
      width: 100%;
      background: #fff;
      z-index: 10;
    }
    .col-4 {
    }
    .mobile-nav-button-outer {
      text-align: right;
      .mobile-nav-button {
        display: none;
        @media (max-width: $width-xl) {
          display: inline-block;
          cursor: pointer;
          height: $height-header;
          padding: 0 10px;
        }
        .txt {
          display: inline-block;
          position: relative;
          top: 0px;
          font-weight: 300;
          letter-spacing: 2px;
          font-size: 100%;
          text-transform: uppercase;
          line-height: $height-header;
          font-family: 'Helvetica Neue LT W05 96 Blk It';
        }
        #nav-icon {
          width: 24px;
          height: 22px;
          position: relative;
          top: 6px;
          margin-left: 15px;
          display: inline-block;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.5s ease-in-out;
          -moz-transition: 0.5s ease-in-out;
          -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out;
          cursor: pointer;
          @media (max-width: 400px) {
            margin-right: 5px;
          }
        }
        #nav-icon span {
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          background: #212121;
          border-radius: 10px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.25s ease-in-out;
          -moz-transition: 0.25s ease-in-out;
          -o-transition: 0.25s ease-in-out;
          transition: 0.25s ease-in-out;
        }
        #nav-icon span:nth-child(1) {
          top: 0px;
        }

        #nav-icon span:nth-child(2),
        #nav-icon span:nth-child(3) {
          top: 8px;
        }

        #nav-icon span:nth-child(4) {
          top: 16px;
        }
        #nav-icon {
          &:hover {
            span {
              background: $c-blue;
            }
          }
        }
      }
      .siteBarOpen {
        #nav-icon span:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }

        #nav-icon span:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          background: $font-color-dark;
        }

        #nav-icon span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          background: $font-color-dark;
        }

        #nav-icon span:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }

    #respons-logo {
      display: none;
      @media (max-width: $width-xl) {
        display: block;
        height: $height-header;
      }
      img {
        display: block;
        margin-left: 5px;
        max-width: 110px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 400px) {
          max-width: 120px;
          position: relative;
        }
      }
    }
  }
}
</style>

<template>
  <header>
    <div class="container-fluid">
      <div class="row justify-content-center align-self-center">
        <div class="col-6">
          <div id="respons-logo">
            <img src="@/assets/img/styria-media-group-logo.svg" alt="Kontakt" class="img-fluid img-logo" />
          </div>
        </div>
        <div class="col-6 mobile-nav-button-outer">
          <div class="mobile-nav-button" icon="account-circle" @click="toggleMenu" :class="{ siteBarOpen: menu }">
            <span class="txt">Menu</span>
            <div id="nav-icon">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentRoute = ref(router.currentRoute);

    /*const toggleMenu = () => {
      store.dispatch('setMenuState', !store.state.menustate);
    };*/
    const menu = computed(() => store.state.menustate);
    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    return {
      menu,
      currentRoute,
      toggleMenu,
    };
  },
});
</script>
