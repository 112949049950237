
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
// @ts-ignore
import TypeWriter from './TypeWriter.vue';

export default defineComponent({
  components: { TypeWriter },
  setup() {
    const router = useRouter();

    return {};
  },
});
