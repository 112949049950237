
import { defineComponent } from 'vue';
import { mdiDesktopMac, mdiTabletAndroid, mdiCellphone, mdiEyeOff, mdiEye } from '@mdi/js';

export default defineComponent({
  setup() {
    return {
      mdiDesktopMac,
      mdiTabletAndroid,
      mdiCellphone,
      mdiEyeOff,
      mdiEye,
    };
  },
});
