import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';

import 'dayjs/locale/de';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Plugins */

import router from './router';
import store from './store';
import VueSmoothScroll from 'vue3-smooth-scroll';

/* Inhouse Plugins */
import { inview } from './plugins/smd-inview';
import { hasperms } from './plugins/smd-hasperms';
import { meta } from './plugins/smd-meta';

/* Components */
import Icon from './components/util/Icon.vue';
import MultiSelect from './components/util/MultiSelect.vue';

import Media from './components/Media.vue';

/* I18n */

import { i18n } from './i18n';
import { emitter } from './emitter';

// const i18n = createI18n({
//   // legacy: false, // you must set `false`, to use Composition API
//   locale: 'de', // set locale
//   fallbackLocale: 'en', // set fallback locale
//   messages,
// });

const app = createApp(App);

app.provide('emitter', emitter);

app.component('Icon', Icon);
app.component('Media', Media);
app.component('MultiSelect', MultiSelect);
app.use(i18n);
app.use(inview);
app.use(hasperms);
app.use(meta);
app.use(store);
app.use(router);
app.use(VueSmoothScroll, {
  updateHistory: false,
  duration: 100,
});

app.mount('#app');
