
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    path: { type: String, required: true },
    width: { type: Number, default: 50 },
    height: { type: Number, default: 50 },
  },
});
