
import { defineComponent } from 'vue';
import Header from '../components/Frontend/Header.vue';
import SitebarNavigation from '../components/Frontend/SitebarNavigation.vue';
import TopSiteNavigation from '../components/Frontend/TopSiteNavigation.vue';

import { mdiChevronUp, mdiArrowTopRight } from '@mdi/js';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    Header,
    SitebarNavigation,
    TopSiteNavigation,
  },

  setup() {
    document.addEventListener('scroll', () => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });

    const store = useStore();
    const apicall = () => {
      store.dispatch('API', { method: 'get', url: 'test' });
    };
    return {
      apicall,
      mdiChevronUp,
      mdiArrowTopRight,
    };
  },
});
