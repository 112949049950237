<style lang="scss" scoped>
.pagination {
  margin: 30px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  @media(max-width:550px) {
    border:0px solid red;
    display:block;
    text-align: center !important;
  }
  button {
    border: 0;
    background: $blue;
    color: #fff;
    font-size: 0.8rem;
    text-transform: uppercase;
    padding: 8px 15px;
    margin: 0 3px;
    letter-spacing: 1px;
    transition: all 200ms ease;
    @media(max-width:300px) {
      transform: scale(.9);
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .full-width-btn {
    @media(max-width:550px) {
      display:block;
      margin: 10px auto;
      &:after {
        content:"";
        display:block;
        width:100%;
      }
    }
  }
  span {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 8px 15px;
  }
}
</style>

<template>
  <div class="pagination">
    <button @click="first" class="full-width-btn">{{ $t('General.pagi-first') }}</button>
    <button @click="prev">{{ $t('General.pagi-prev') }}</button>
    <span>{{ value + 1 }} / {{ pages }}</span>
    <button @click="next">{{ $t('General.pagi-next') }}</button>
    <button @click="last" class="full-width-btn">{{ $t('General.pagi-last') }}</button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: { type: Number, required: true },
    pages: { type: Number, default: 0 },
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const prev = () => {
      if (value.value > 0) value.value--;
    };
    const next = () => {
      if (value.value < props.pages - 1) value.value++;
    };

    const first = () => {
      value.value = 0;
    };

    const last = () => {
      value.value = props.pages - 1;
    };

    return {
      value,
      prev,
      next,
      first,
      last,
    };
  },
});
</script>
