<style lang="scss">
#app {
  //font-family: Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $font-color-dark;
  //overflow-x: hidden;

  font-family: 'Helvetica Neue LT W05 45 Light';
  a {
    text-decoration: none;
  }

  .vc-select select {
    padding: 0px !important;
    padding-left: 8px !important;
    padding-right: 25px !important;
  }
}
</style>

<template>
  <router-view />
  <Notification />
  <Debug />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Notification from './components/Notification.vue';
import Debug from './components/Debug.vue';

export default defineComponent({
  components: {
    Notification,
    Debug,
  },
});
</script>
