<style lang="scss" scoped>
.mediacontainer {
  padding: 0px 0;
  &.hasdescription {
    .description {
      padding: 8px 5px;
      font-size: 90%;
      font-family: 'Helvetica Neue LT W05 56 Italic';
      letter-spacing: 1px;
    }
  }
  .cover {
    background-size: cover;

    video {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <div class="mediacontainer" :class="{ hasdescription: description }" v-if="media != -1 && entity">
    <div class=" cover" v-if="scaling == 1" :style="style">
      <video controls playsinline v-if="entity.type == 'video' && scaling == 1">
        <source :src="entity.url" />
      </video>
    </div>
    <img :src="imagesize == 'none' ? entity.url : `${entity.url}/${imagesize}`" :alt="entity.name" v-if="entity.type == 'image' && scaling != 1" :style="style" />
    <video controls playsinline v-if="entity.type == 'video' && scaling != 1" :style="style">
      <source :src="entity.url" />
    </video>
    <div class="description" v-if="description">
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    media: { type: Number, required: true },
    width: { type: String, default: '100%' },
    height: { type: String, default: 'auto' },
    imagesize: { type: String, default: 'none' },
    scaling: { type: Number, default: 0 },
    position: { type: String, default: 'center' },
    description: { type: String },
  },
  setup(props) {
    const entity = ref<MediaEntity>();
    const store = useStore();
    store.dispatch('fetchEntity', props.media).then((res: MediaEntity) => (entity.value = res));

    const style = computed(() => {
      if (entity.value) {
        if (props.scaling != 1) {
          return `width:${props.width};height:${props.height}`;
        } else {
          return `width:${props.width};height:${props.height};background-image:url(${entity.value.url});background-position:${props.position}`;
        }
      }
      return '';
    });

    return {
      entity,
      style,
    };
  },
});
</script>
