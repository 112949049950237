
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    media: { type: Number, required: true },
    width: { type: String, default: '100%' },
    height: { type: String, default: 'auto' },
    imagesize: { type: String, default: 'none' },
    scaling: { type: Number, default: 0 },
    position: { type: String, default: 'center' },
    description: { type: String },
  },
  setup(props) {
    const entity = ref<MediaEntity>();
    const store = useStore();
    store.dispatch('fetchEntity', props.media).then((res: MediaEntity) => (entity.value = res));

    const style = computed(() => {
      if (entity.value) {
        if (props.scaling != 1) {
          return `width:${props.width};height:${props.height}`;
        } else {
          return `width:${props.width};height:${props.height};background-image:url(${entity.value.url});background-position:${props.position}`;
        }
      }
      return '';
    });

    return {
      entity,
      style,
    };
  },
});
