<style lang="scss">
#frontend {
  .newsflash-outer {
    overflow-x: hidden;
    //border:1px solid red;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    white-space: nowrap;
    //overflow-x:hidden;

    .newsflashcontainer {
      display: flex;
      margin-top: 30px;
      margin-bottom: 90px;
      @media (max-width: $width-sm) {
        margin-bottom: 20px;
      }
    }

    a {
      display: inline-block;
      //border:1px solid yellow;
      color: #000;
      padding: 10px 15px;
      font-size: 24px;
      font-family: 'Call Two OT W03 Regular';
      line-height: 80%;
      text-transform: uppercase;
      letter-spacing: 2px;
      @media (max-width: $width-lg) {
        font-size: 18px;
      }
      //transform-origin: bottom;
      //transform: scaleY(.9);

      span {
        display: inline-block;
        margin-left: 20px;
      }

      &:hover {
        color: #000;
        opacity: 0.8;
      }
    }
  }
}
</style>

<template>
  <div class="newsflash-outer" v-if="elements.length >= 4">
    <!-- <div class="container-fluid">
      <div class="row">
        <div class="col-12"> -->
    <div class="newsflashcontainer">
      <div class="newsflash-inner" ref="container" :style="`width:${width}px`" @mouseenter="pause" @mouseleave="play">
        <a :href="element.link" :key="element.id" v-for="element of elements" :target="element.newwindow ? '_blank' : '_self'">{{ element.text }} <span>// </span></a>
        <a :href="element.link" :key="element.id" v-for="element of elements" :target="element.newwindow ? '_blank' : '_self'">{{ element.text }} <span>// </span></a>
      </div>
    </div>

    <!-- </div>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import store from '@/store';
import { defineComponent, ref, onMounted, nextTick } from 'vue';

type Newsflash = {
  id: number;
  text: string;
  link: string;
  order: number;
  newwindow: boolean;
  createdat: Date;
};

export default defineComponent({
  setup() {
    const width = ref(0);
    const container = ref<HTMLElement>();
    const elements = ref<Array<Newsflash>>([]);

    const speed = 60000;

    let animation: Animation;

    const animate = () => {
      if (container.value !== undefined) {
        animation = container.value.animate([{ transform: `translateX(0)` }, { transform: `translateX(-50%)` }], { duration: speed, easing: 'linear', iterations: Infinity });
      }
    };
    onMounted(() => {
      store.dispatch('fetchNewsflash').then((res: Array<Newsflash>) => {
        elements.value = res;
        nextTick(() => {
          if (container.value !== undefined) {
            const items = container.value.children;
            for (const item of items) {
              width.value += item.getBoundingClientRect().width;
            }
            animate();
          }
        });
      });
    });

    const pause = () => {
      if (animation !== undefined) {
        animation.pause();
      }
    };

    const play = () => {
      if (animation !== undefined) {
        animation.play();
      }
    };

    return {
      container,
      width,
      pause,
      play,
      elements,
    };
  },
});
</script>
