
import { defineComponent, computed } from 'vue';
import Navigation from '../components/Backend/Navigation.vue';
import Header from '../components/Backend/Header.vue';
import SubHeader from '../components/Backend/SubHeader.vue';
import Footer from '../components/Backend/Footer.vue';
import Notification from '../components/Notification.vue';
import { useStore } from 'vuex';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  components: {
    Navigation,
    Header,
    SubHeader,
    Footer,
    Notification,
  },
  setup() {
    document.addEventListener('scroll', () => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });

    const store = useStore();
    const menustate = computed(() => store.state.menustate);
    return {
      menustate,
      mdiChevronUp,
    };
  },
});
