<style lang="scss">
#frontend {
  .abobox-startseite {
    position: relative;
    padding-bottom: 150px;
    [class*='col-'] {
      //border:1px solid red;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 48%;
      background: #f6f6f6;
      position: absolute;
      left: 0;
      top: 23%;
      z-index: -1;
      @media (max-width: $width-md) {
        top: 15%;
        height: 65%;
      }
    }
    .abo-box-inner {
      position: relative;
      left: 100px;
      margin-bottom: -60px;
      @media (max-width: $width-xl) {
        left: 50px;
      }
      @media (max-width: $width-lg) {
        left: 0px;
      }
    }

    .img-v1 {
      position: relative;
      left: 0px;
      top: -65px;
      max-width: 80%;
      @media(max-width:$width-md) {
        max-width: 100%;
        left: 0;
        top: 0;
        display: block;
        margin: 20px auto;
      }
     
    }
    .img-v2 {
      position: relative;
      top: -40px;
      margin-bottom: 100px;
      max-width: 70%;
      left: -60px;
      @media (max-width: 1400px) {
        top: -30px;
      }
      @media (max-width: $width-xl) {
        top: -10px;
      }
      @media (max-width: $width-lg) {
        top: 0px;
        left:-60px;
      }
     
      @media (max-width: $width-md) {
        top: 0;
        left: 0;
        margin-bottom: 0;
        max-width: 100%;
        display: block;
        margin: 20px auto;
      }
    }
    .img-v3 {
      position: relative;
      top: -40px;
      left: -60px;
      
      @media (max-width: $width-md) {
        left: 0;
      }
      @media (max-width: $width-md) {
        top: 0;
        left: 0;
        display: block;
        margin: 20px auto;
      }
    }
    .img-v4 {
      @media (max-width: $width-md) {
        display: block;
        margin: 20px auto;
      }
    }

    .img-v5 {
      max-width: 70%;
      position: relative;
      margin: 0 auto;
      display: block;
      left: -218px;
      top: -180px;
      //top:-170px;
      @media (max-width: 1400px) {
        left: -196px;
        top: -155px;
      }
      @media (max-width: $width-xl) {
        left: -176px;
        top: -120px;
      }
      @media (max-width: $width-lg) {
        left: -189px;
        top: -100px;
      }
      @media (max-width: $width-lg) {
        left: -130px;
      }
      @media (max-width: $width-md) {
        top: 0;
        max-width: 100%;
        left: 0;
        display: block;
        margin: 20px auto;
      }
    }

    .text-align-right {
      text-align: right;
    }
    .txt-bottom {
      font-family: 'Helvetica Neue LT W05_75 Bold';
      text-align: center;
      font-size: 150%;
      font-weight: 700;
      margin-bottom: 60px;

      letter-spacing: 1px;
      @media (max-width: $width-md) {
        margin-top: 70px;
      }
      .breaker {
        display: block;
        height: 1px;
      }
    }
  }
}
</style>

<template>
  <div class="abobox-startseite">
    <div class="container">
      <div class="row header-section-box  align-self-center">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">{{$t('Home.aboservice')}}</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1 v-html="$t('Home.abo-title')"></h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="abo-box-inner">
        <div class="row justify-content-center align-self-center">
          <div class="col-lg-5 col-md-6 col-sm-8 col-9" v-inview="'move-in-from-left'">
            <img src="@/assets/img/startseite/aboservice-v6.jpg" alt="Presse" class="img-fluid img-v4" />
          </div>
          <div class="col-lg-5 col-md-6 col-sm-8 col-9 align-self-end" v-inview="'move-in-from-top'">
            <img src="@/assets/img/startseite/aboservice-v1-v2.jpg" alt="SPORTaktiv" class="img-fluid img-v1" />
          </div>
        </div>
        <div class="row align-self-center justify-content-center">
          <div class="col-lg-5 col-md-6 col-sm-8 col-9 justify-content-end align-self-center text-align-right" v-inview="'move-in-from-left'">
            <img src="@/assets/img/startseite/aboservice-v2.jpg" alt="Kleine Zeitung" class="img-fluid img-v2" />
          </div>
          <div class="col-lg-5 col-md-6 col-sm-8 col-9 align-self-end" v-inview="'move-in-from-right'">
            <img src="@/assets/img/startseite/aboservice-v3.jpg" alt="Diva Wienerin" class="img-fluid img-v3" />
          </div>
        </div>
        <div class="row align-self-center text-align-right justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-8 col-9 justify-content-end">
            <img src="@/assets/img/startseite/aboservice-v4.jpg" alt="Beauty Style" class="img-fluid img-v5" />
          </div>
        </div>
      </div>
      <div class="row txt-box-outer">
        <div class="col-12">
          <div class="txt-bottom" v-html="$t('Home.abo-text')"></div>
          <div class="btn-std">
            <router-link :to="{ name: 'service' }"
              ><span class="txt">{{$t('Home.abo-btn')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();

    return {};
  },
});
</script>
