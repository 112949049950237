const mail = {
  state: {},
  mutations: {},
  actions: {
    sendMail(context: any, payload: { type: 'contact' | 'service' | 'startups'; locale: 'de' | 'en'; data: any }) {
      return context.dispatch('API', { url: `mailer/${payload.locale}/${payload.type}`, method: 'post', data: payload.data });
    },
  },
  getters: {},
};

export default mail;
