const translations = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    insertTranslation(context: any, payload: { variable: string; domain: string; de: string; en: string }) {
      return context.dispatch('API', { url: 'i18n', method: 'post', data: payload });
    },
    updateTranslation(context: any, payload: { id: number; variable: string; domain: string; de: string; en: string }) {
      return context.dispatch('API', { url: 'i18n', method: 'patch', data: payload });
    },
    fetchTranslations(context: any) {
      return context.dispatch('API', { url: 'i18n' }).then((res: any) => Promise.resolve(res.data.message));
    },
    deleteTranslation(context: any, id: number) {
      return context.dispatch('API', { url: `i18n/${id}`, method: 'delete' }).then((res: any) => Promise.resolve(res.data.message));
    },
  },
};

export default translations;
