const api = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    deleteFile(context: any, deletekey: string) {
      return context.dispatch('API', { url: `files/${deletekey}`, method: 'delete' });
    },
    uploadFile(context: any, { file, progresshandler, media }: { file: any; progresshandler: Function; media: boolean }) {
      const data = new FormData();
      data.append('file', file);
      //console.log(data);
      return context
        .dispatch('API', {
          url: media ? 'medialib' : 'files',
          method: 'put',
          data,
          progresshandler,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res: any) => res.data.message);
    },
  },
};

export default api;
