import { App, Plugin } from 'vue';

type InviewRecord = {
  el: HTMLElement;
  event: Function | string | Array<string>;
  toggle: boolean;
  primed: boolean;
};

const triggerEvent = (el: InviewRecord) => {
  el.primed = false;
  if (typeof el.event == 'function') {
    el.event();
  } else if (typeof el.event == 'string') {
    el.el.classList.add(el.event);
  } else if (Array.isArray(el.event)) {
    for (const clazz of el.event) {
      el.el.classList.add(clazz);
    }
  }
};

const untriggerEvent = (el: InviewRecord) => {
  if (el.toggle) el.primed = true;

  if (typeof el.event == 'string') {
    el.el.classList.remove(el.event);
  } else if (Array.isArray(el.event)) {
    for (const clazz of el.event) {
      el.el.classList.remove(clazz);
    }
  }
};

// const isVisible => (el: HTMLElement) => {
//  // if(el.getBoundingClientRect().top <= window.innerHeight )
//   return false;
// }

export const inview: Plugin = {
  install: (app: App) => {
    const elements: Array<InviewRecord> = [];

    document.addEventListener('scroll', () => {
      for (const element of elements) {
        if (element.primed && element.el.getBoundingClientRect().top <= window.innerHeight && element.el.getBoundingClientRect().bottom >= 0) {
          triggerEvent(element);
        }
        if (element.el.getBoundingClientRect().bottom < 0 || element.el.getBoundingClientRect().top > window.innerHeight) {
          untriggerEvent(element);
        }
      }
    });

    app.directive('inview', (el: HTMLElement, binding, vnode) => {
      elements.push({
        el: el,
        toggle: binding.arg == 'toggle',
        event: binding.value,
        primed: true,
      });
    });
  },
};
