
import store from '@/store';
import { defineComponent, ref, onMounted, nextTick } from 'vue';

type Newsflash = {
  id: number;
  text: string;
  link: string;
  order: number;
  newwindow: boolean;
  createdat: Date;
};

export default defineComponent({
  setup() {
    const width = ref(0);
    const container = ref<HTMLElement>();
    const elements = ref<Array<Newsflash>>([]);

    const speed = 60000;

    let animation: Animation;

    const animate = () => {
      if (container.value !== undefined) {
        animation = container.value.animate([{ transform: `translateX(0)` }, { transform: `translateX(-50%)` }], { duration: speed, easing: 'linear', iterations: Infinity });
      }
    };
    onMounted(() => {
      store.dispatch('fetchNewsflash').then((res: Array<Newsflash>) => {
        elements.value = res;
        nextTick(() => {
          if (container.value !== undefined) {
            const items = container.value.children;
            for (const item of items) {
              width.value += item.getBoundingClientRect().width;
            }
            animate();
          }
        });
      });
    });

    const pause = () => {
      if (animation !== undefined) {
        animation.pause();
      }
    };

    const play = () => {
      if (animation !== undefined) {
        animation.play();
      }
    };

    return {
      container,
      width,
      pause,
      play,
      elements,
    };
  },
});
