
import { defineComponent } from 'vue';

import Notification from './components/Notification.vue';
import Debug from './components/Debug.vue';

export default defineComponent({
  components: {
    Notification,
    Debug,
  },
});
