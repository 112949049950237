<style lang="scss">
.multiselect-container {
  position: relative;
  cursor: pointer;
  .opener {
    display: block;
    width: 100%;
    border-radius: 0 !important;
    border: 1px solid #eee;
    padding: 0.7em 0.8em;
    outline: none;
  }

  .itemcontainer {
    transform: translateY(4px);
    position: absolute;
    width: 100%;
    background-color: #fff;
    border-radius: 0 !important;
    border: 1px solid #eee;
    padding: 0.7em 0.8em;
    outline: none;
    .item {
      &.selected {
        background-color: #eee;
      }
    }
  }
}
</style>

<template>
  <div class="multiselect-container" ref="container" tabindex="1" @blur="containerBlur">
    <div class="opener" @click="onButtonClick">{{ openerText }}</div>
    <div class="itemcontainer" v-if="open">
      <div class="item" :class="{ selected: model.indexOf(item.value) > -1 }" :key="index" v-for="(item, index) in items" @click="onItemClick(item.value)">
        <Icon :path="mdiCheck" :width="15" :height="15" v-if="model.indexOf(item.value) > -1" />
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue';
import { mdiCheck } from '@mdi/js';

type MultiSelectItem = {
  label: string;
  value: any;
};

export default defineComponent({
  props: {
    items: { type: Array as PropType<Array<MultiSelectItem>>, default: () => [] },
    placeholder: { type: String, default: 'Bitte wählen' },
    modelValue: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const container = ref<HTMLElement>();
    const open = ref(false);
    const model = computed({
      get: () => props.modelValue,
      set: (value: any) => emit('update:modelValue', value),
    });

    // Events
    const onButtonClick = () => {
      container.value?.focus();
      open.value = !open.value;
    };

    const onItemClick = (value: any) => {
      if (model.value.indexOf(value) > -1) {
        model.value.splice(model.value.indexOf(value), 1);
      } else {
        model.value.push(value);
      }
    };

    const openerText = computed(() => {
      if (model.value.length > 0) {
        let text = '';
        for (const index in model.value) {
          const label = props.items.find((item: MultiSelectItem) => item.value == model.value[index])?.label || model.value[index];
          text = text == '' ? `${label}` : `${text}, ${label}`;
        }

        return text;
      } else {
        return props.placeholder;
      }
    });

    const containerBlur = () => (open.value = false);

    return {
      open,
      model,
      onButtonClick,
      onItemClick,
      openerText,
      containerBlur,
      container,
      mdiCheck,
    };
  },
});
</script>
