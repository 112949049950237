import { RouteRecordRaw } from 'vue-router';

import BackendContainer from '../views/BackendContainer.vue';
import BEIndex from '../views/Backend/Index.vue';

export const backendroutes: Array<RouteRecordRaw> = [
  {
    path: '/admin',
    redirect: '/de/admin',
  },
  {
    path: '/:locale/admin',
    component: BackendContainer,
    meta: {
      permission: 'frontend.backend.see',
    },
    children: [
      {
        path: '',
        name: 'AdminHome',
        component: BEIndex,
        meta: {
          permission: 'frontend.backend.see',
        },
      },
      {
        path: 'changepassword',
        name: 'ChangePassword',
        component: () => import(/* webpackChunkName: "ChangePassword" */ '../views/Backend/ChangePassword.vue'),
      },
      {
        path: 'vorschau-devices',
        name: 'vorschau-devices',
        component: () => import(/* webpackChunkName: "TestSite" */ '../views/Backend/PreviewDevices.vue'),
      },
      {
        path: 'styleguide',
        name: 'StyleGuide',
        component: () => import(/* webpackChunkName: "TestSite" */ '../views/Backend/StyleGuide.vue'),
      },
      {
        path: 'entries/new',
        name: 'CreateEntry',
        component: () => import(/* webpackChunkName: "CreateEntry" */ '../views/Backend/EntryDetails.vue'),
        meta: {
          permission: 'entry.edit',
        },
      },
      {
        path: 'entries/edit/:id:',
        name: 'EditEntry',
        component: () => import(/* webpackChunkName: "CreateEntry" */ '../views/Backend/EntryDetails.vue'),
        meta: {
          permission: 'entry.edit',
        },
      },
      {
        path: 'entries/overview',
        name: 'OverviewEntry',

        component: () => import(/* webpackChunkName: "OverviewEntry" */ '../views/Backend/EntryIndex.vue'),
        meta: {
          permission: 'entry.see',
        },
      },
      {
        path: 'medialib',
        name: 'MediaLib',
        component: () => import(/* webpackChunkName: "MediaLibPage" */ '../views/Backend/MediaLibPage.vue'),
        meta: {
          permission: 'media.see',
        },
      },
      //   { path: 'newsite',
      //   name: 'NeueSeite',
      //   component: () => import(/* webpackChunkName: "NeueSeite" */ '../views/Backend/NeueAdminSeite.vue')
      // },
      {
        path: 'career',
        name: 'CareerOverview',
        component: () => import(/* webpackChunkName: "CareerOverview" */ '../views/Backend/CareerOverview.vue'),
        meta: {
          permission: 'career.see',
        },
      },
      {
        path: 'newsflash',
        name: 'Newsflash',
        component: () => import(/* webpackChunkName: "Newsflash" */ '../views/Backend/Newsflash.vue'),
        meta: {
          permission: 'newsflash.see',
        },
      },
      {
        path: 'category/keywords',
        name: 'CategoryKeywords',
        component: () => import(/* webpackChunkName: "CategoryKeywords" */ '../views/Backend/CategoryKeywords.vue'),
      },
      {
        path: 'author',
        name: 'AuthorOverview',
        component: () => import(/* webpackChunkName: "AuthorOverview" */ '../views/Backend/AuthorOverview.vue'),
      },
      {
        path: 'profil',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "AuthorOverview" */ '../views/Backend/Profile.vue'),
      },
      {
        path: 'sprachen-text',
        name: 'LanguageVariables',
        component: () => import(/* webpackChunkName: "AuthorOverview" */ '../views/Backend/LanguageVariables.vue'),
        meta: {
          permission: 'i18n.see',
        },
      },

      //{ path: 'newsite', name: 'NeueSeite', component: () => import(/* webpackChunkName: "NeueSeite" */ '../views/Backend/NeueAdminSeite.vue')
      //},
    ],
  },
];
