<style lang="scss">
#frontend {
  #header {
    background: #fff;
    padding: 42px 0;
    transition: all 200ms ease;
    @media (max-width: $width-lg) {
      padding: 25px 15px;
    }
  
    .container-fluid {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
      padding-left: var(--bs-gutter-x, 1.75rem);
      padding-right: var(--bs-gutter-x, 1.75rem);
      @media (max-width: $width-xl) {
        padding-left: var(--bs-gutter-x, 0.75rem);
        padding-right: var(--bs-gutter-x, 0.75rem);
      }
    }
    .header-left {
      display: flex;

      #burger-nav-icon {
        width: 30px;
        height: 30px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        //border:1px solid red;
        position: relative;
        top: 3px;
      }

      #burger-nav-icon span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #212121;
        //border-radius: 15px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      #burger-nav-icon span:nth-child(1) {
        top: 0px;
      }

      #burger-nav-icon span:nth-child(2),
      #burger-nav-icon span:nth-child(3) {
        top: 10px;
        width: 80%;
      }

      #burger-nav-icon span:nth-child(4) {
        top: 20px;
      }

      #burger-nav-icon.sitebaropen span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }

      #burger-nav-icon.sitebaropen span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 12px;
      }

      #burger-nav-icon.sitebaropen span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 12px;
      }

      #burger-nav-icon.sitebaropen span:nth-child(4) {
        top: 24px;
        width: 0%;
        left: 50%;
      }

      #burger-nav-icon {
        &:hover {
          span {
            background: #ccc;
          }
        }
      }
    }
    .language-box {
      margin-left: 20px;
      @media (max-width: 992px) {
        display: none;
      }
      .language-inner {
        display: inline-block;
        position: relative;
        top: 3px;
        line-height: 13px;
        font-size: 14px;
        padding: 0px 10px;
        cursor: pointer;
        letter-spacing: 1px;
        &:hover {
          opacity: 0.7;
          color:#000;
        }
      }
      .router-link-active {
        font-family: 'Helvetica Neue LT W05 65 Medium';
      }
      .lang-de {
        margin-left: 10px;
        border-right: 1px solid #000;
      }
    }
    .col-logo {
      a {
        display: inline-block;
        width: 150px;
      }
      .img-logo {
        width: 100%;
      }
    }

    .header-right {
      @media (max-width: 500px) {
        display: none !important;
      }
      .link-top {
        padding: 5px 9px;

        letter-spacing: 1px;
        a {
          color: #000;

          font-size: 90%;
          .txt {
            @media (max-width: 992px) {
              display: none;
            }
          }
          .icon-img {
            display: inline-block;
            margin-left: 5px;
            img {
              width: 22px;
              @media (max-width: 992px) {
                width: 25px;
              }
            }
          }
          .icon-img-lupe {
            width: 20px;
            position: relative;
            top:1px;

          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .link-share {
        // margin-right: -5px;
        transform: translate(4px, 0px);
      }
    }
  }
  .scroll-header {
    //padding: 25px 0 !important;
    padding-top:25px !important;
    padding-bottom:25px !important;
    //border:1px solid red;
  
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    @media (max-width: $width-lg) {
      //padding: 25px 15px;
    }
  }
}
</style>

<template>
  <header class="fixed-top" id="header">
    <div class="container-fluid">
      <div class="row top-socket">
        <div class="col align-self-center header-left">
          <div id="burger-nav-icon" @click="emitter.emit('toggleSidebar')" :class="{ sitebaropen: open }">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="language-box">
            <router-link tag="div" class="language-inner lang-de" :to="{ params: { locale: 'de' } }">DE</router-link>
            <router-link tag="div" class="language-inner lang-en" :to="{ params: { locale: 'en' } }">EN</router-link>
          </div>
        </div>
        <div class="col d-flex justify-content-center align-self-center col-logo">
          <router-link :to="{ name: 'Home' }"><img src="@/assets/img/styria-media-group-logo-v3.svg" alt="Kontakt" class="img-fluid img-logo"/></router-link>
        </div>
        <div class="col d-flex justify-content-end align-self-center header-right">
          <!--<div class="link-top link-share">
            <router-link :to="{ name: 'Home' }"
              ><span class="icon-img"><Icon :path="mdiShareVariantOutline" :width="22" :height="22"/></span
            ></router-link>
          </div>-->
          <div class="link-top link-kontakt">
            <router-link :to="{ name: 'kontakt' }"
              ><span class="icon-img"><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span></router-link>
          </div>
          <div class="link-top link-suche">
            <router-link :to="{ name: 'suche' }"
              ><span class="icon-img icon-img-lupe"><img src="@/assets/img/header-suche-icon-v2.svg" alt="Suche" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { Emitter } from 'mitt';
import { mdiShareVariantOutline } from '@mdi/js';

export default defineComponent({
  setup() {
    const router = useRouter();
    const currentRoute = ref(router.currentRoute);
    const emitter = inject('emitter') as Emitter;
    const open = ref(false);

    emitter.on('toggleSidebar', () => {
      open.value = !open.value;
    });

    emitter.on('closeSidebar', () => {
      open.value = false;
    });

    document.addEventListener('scroll', () => {
      const headerClassAdd = document.getElementById('header');
      if (!headerClassAdd) return;
      if (document.documentElement.scrollTop > 180) {
        headerClassAdd.classList.add('scroll-header');
      } else {
        headerClassAdd.classList.remove('scroll-header');
      }
    });

    return {
      emitter,
      currentRoute,
      open,
      mdiShareVariantOutline,
    };
  },
});
</script>
