import { APIResult } from './api';

const career = {
  state: {},
  mutations: {},
  actions: {
    fetchCareers(context: any) {
      return context.dispatch('API', { url: 'career' }).then((res: APIResult) => {
        return res.data.message;
      });
    },
    insertCareer(context: any, payload: any) {
      return context.dispatch('API', { url: 'career', method: 'post', data: payload });
    },
    updateCareer(context: any, payload: any) {
      return context.dispatch('API', { url: `career/${payload.id}`, method: 'patch', data: payload });
    },
    updateCareerOrder(context: any, payload: any) {
      return context.dispatch('API', { url: `career/order`, method: 'patch', data: payload });
    },
    deleteCareer(context: any, payload: any) {
      return context.dispatch('API', { url: `career/${payload.id}`, method: 'delete' });
    },
  },
  getters: {},
};

export default career;
