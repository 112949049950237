<style lang="scss">
#frontend {
}
</style>

<template>
  <div class="newsbox-frontend">
    <div class="container">
      <div class="row header-section-box justify-content-center align-self-center">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">newsroom</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1>LATEST<br />NEWS</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-self-center">
        <EntryIndex category="2" :limit="10" slider />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import EntryIndex from '@/components/Frontend/EntryIndex.vue';
export default defineComponent({
  components: {
    EntryIndex,
  },
});
</script>
