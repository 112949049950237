
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { defineComponent } from 'vue';
import InfoBox from '../../components/Backend/InfoBox.vue';

export default defineComponent({
  components: {
    InfoBox,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    if (store.getters.hasPerm('entry.see')) {
      router.push({ name: 'OverviewEntry' });
    } else if (store.getters.hasPerm('i18n.see')) {
      router.push({ name: 'LanguageVariables' });
    }
  },
});
