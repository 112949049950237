<style lang="scss">
#frontend {
  .socialmediaiconbox-outer {
    .social-media-icon-box {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 99;

        //left: 100px;
        //top: 65px;
        // @media (max-width: 1200px) {
        //   left: 50px;
        //   top: 150px;
        // }
        // @media (max-width: 1024px) {
        //   left: 50px;
        //   top: 20px;
        // }
        @media (max-width: $width-lg) {
          display: none;
        }
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          li {
            padding: 13px;
            width: 50px;
            height: 50px;

            &.linkedin {
              background: $blue;
            }

            &.instagram {
              background: $coral;
            }

            &.facebook {
              background: $fb-violet;
            }

            &.youtube {
              background: $wine-red;
            }

            a {
              img {
                display: block;
                width: 100%;
                transition: all 200ms ease;
              }
              &:hover {
                img {
                  transform: scale(1.15);
                }
              }
            }
          }
        }
      }
  }
}
</style>

<template>
  <div class="socialmediaiconbox-outer">
    <div class="social-media-icon-box">
        <ul>
          <li class="linkedin">
            <a href="https://at.linkedin.com/company/styria-medien-ag" target="_blank"><img src="@/assets/img/icon-linkedin.svg" alt="Linked In" class="img-fluid"/></a>
          </li>
          <li class="instagram">
            <a href="https://www.instagram.com/styriamediagroup/" target="_blank"><img src="@/assets/img/icon-instagram.svg" alt="Instagram" class="img-fluid"/></a>
          </li>
          <li class="facebook">
            <a href="https://www.facebook.com/styriamediagroup" target="_blank"><img src="@/assets/img/icon-facebook.svg" alt="Facebook" class="img-fluid"/></a>
          </li>
          <li class="youtube">
            <a href="https://www.youtube.com/channel/UCIeEj9CidJZY6ymknOAAtaA" target="_blank"><img src="@/assets/img/icon-youtube.svg" alt="Youtube" class="img-fluid"/></a>
          </li>
        </ul>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  

});
</script>
