<style lang="scss" scoped>
.news-item {
  position: relative;
  @media (max-width: $width-lg) {
    margin: 15px auto;
  }
  .img-box {
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .txt-box {
    padding: 40px 50px;
    border: 1px solid #cfcfcf;
    @media (max-width: $width-xl) {
      padding: 30px 30px;
    }
    a {
      display: block;
      &:hover {
        color: #000;
        opacity: 0.7;
      }
    }
    .date {
      font-family: 'Helvetica Neue LT W05 65 Medium';
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 500;
    }
    h2 {
      font-family: 'Helvetica Neue LT W05_75 Bold';
      margin-bottom: 20px;
      line-height: 1.3;
      font-size: 1.6rem;
      text-transform: none;
      @media (max-width: $width-xl) {
        font-size: 140%;
      }
      @media (max-width: $width-md) {
        font-size: 120%;
      }
    }
    p {
      //font-family:"Helvetica Neue LT W05 35 Thin";
      line-height: 1.8;
      letter-spacing: 1px;
    }
    .keywords {
      margin-top: 30px;
      margin-bottom: 30px;
      span {
        display: inline-block;
        a {
          font-family: 'Helvetica Neue LT W05 45 Light';
          display: inline-block;
          color: #ccc;
          margin-right: 5px;
          font-size: 90%;
          letter-spacing: $letter-spacing-std;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
  .btn-mehr-lesen {
    border: 1px solid #cfcfcf;
    border-top: 0;
    a {
      font-family: 'Helvetica Neue LT W05 65 Medium';
      display: block;
      padding: 20px 50px;
      color: #000;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      transition: $transition-std;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &:hover {
    .img-box {
      opacity: 0.7;
      transition: $transition-std;
    }
  }
}
</style>

<template>
  <article class="news-item" v-if="localentry">
    <div class="img-box">
      <router-link :to="{ name: routename, params: { id: niceid } }">
        <Media :media="entry.thumbnail" imagesize="500xauto" />
      </router-link>
    </div>
    <div class="txt-box">
      <div class="date">{{ publishdate }}</div>
      <router-link :to="{ name: routename, params: { id: niceid } }">
        <h2 v-html="localentry.title"></h2>
        <p>{{ localentry.introtext }}</p>
      </router-link>
      <div class="keywords" v-if="keywords?.length && keywords.length > 0">
        <span :key="keyword" v-for="keyword in keywords">
          <router-link :to="{ name: 'newskeyword', params: { keyword: keyword } }" v-if="keyword">#{{ keyword }}</router-link>
        </span>
      </div>
    </div>
    <div class="btn-mehr-lesen ">
      <router-link :to="{ name: routename, params: { id: niceid } }">{{ $t('General.read-more') }}</router-link>
    </div>
  </article>

  <!-- <article class="news-item" v-if="localentry">
    <div class="img-box" @click="click">
      <Media :media="entry.thumbnail" />
    </div>
    <div class="txt-box" @click="click">
      <div class="date"></div>

      <h2>{{ localentry.title }}</h2>
      <p>{{ localentry.introtext }}</p>

      <div class="keywords">
        <router-link :to="{ name: 'keywordview', params: { keyword: keyword } }" custom v-slot="{ navigate }" :key="keyword" v-for="keyword of keywords">
          <span @click="navigate"> #{{ keyword }} </span>
        </router-link>
      </div>
    </div>
    <div class="btn-mehr-lesen" @click="click">
      Mehr lesen
    </div>
  </article> -->
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import dayjs from 'dayjs';
import entry from '@/store/entry';
import { mdiTrayMinus } from '@mdi/js';
import { useI18n } from 'vue-i18n';

type i18nEntryWithId = i18nEntry & { id: number };

export default defineComponent({
  props: {
    entry: { type: Object as PropType<i18nEntryWithId>, required: true },
    lang: { type: String as PropType<'en' | 'de'>, default: 'de' },
    click: { type: Object as PropType<(payload: MouseEvent) => void> },
    routename: { type: String, default: 'newsdetails' },
  },

  setup(props) {
    const publishdate = computed(() => {
      if (props.entry.publishdate !== null) {
        return dayjs(props.entry.publishdate)
          .locale(props.lang)
          .format('DD. MMMM YYYY HH:MM');
      } else {
        return '';
      }
    });

    const i18n = useI18n();
    const localentry = computed(() => {
      const locale = i18n.locale.value as 'de' | 'en';
      if (props.entry[locale]) return props.entry[locale];
      return undefined;
    });

    const keywords = computed(() => localentry.value?.keywords.toString().split(','));

    const niceid = computed(() => {
      let id = props.entry.id.toString();
      if (localentry.value) {
        id +=
          '-' +
          localentry.value.title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/-\s*$/, '')
            .replace(/^-\s*/, '');
      }

      return id;
    });

    return {
      publishdate,
      localentry,
      keywords,
      niceid,
    };
  },
});
</script>
