import { App, Plugin, VNode, computed } from 'vue';
import store from '../store';
export const hasperms: Plugin = {
  install: (app: App) => {
    app.directive('hasperm', (el: HTMLElement, binding, vnode: VNode) => {
      if (!binding.modifiers.not) {
        if (!store.getters.hasPerm(binding.value)) {
          el.remove();
        }
      } else {
        if (store.getters.hasPerm(binding.value)) {
          el.remove();
        }
      }
    });
  },
};
