import dayjs from 'dayjs';
import { nextTick } from 'vue';
import { createI18n, useI18n } from 'vue-i18n';
import store from './store';

export const SUPPORT_LOCALES = ['en', 'de'];
export const i18n = createI18n({ locale: 'de', fallbackLocale: 'de' });

export function setI18nLanguage(locale: any) {
  // document.querySelector('html').setAttribute('lang', locale);
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale = locale;
  }
}

export async function loadLocaleMessages(locale: any, domain: string) {
  setI18nLanguage(locale);

  const messages = await store.dispatch('API', { url: `i18n/domain/${domain}/${locale}`, skipAuth: true }).then((res: any) => {
    return Promise.resolve(res.data.message);
  });

  i18n.global.mergeLocaleMessage(locale, messages);

  return nextTick();
}

export function formatDate(date: Date, format?: string) {
  return dayjs(date)
    .locale(useI18n().locale.value)
    .format(format || 'DD. MMMM YYYY HH:MM');
}
