import { createStore } from 'vuex';
import auth from './auth';
import api, { APIResult } from './api';
import notifications from './notifications';
import media from './media';
import entry from './entry';
import translations from './translations';
import career from './career';
import newsflash from './newsflash';
import mail from './mail';
import files from './files';

const APIURL = process.env.VUE_APP_API;
const FRONTENDURL = process.env.VUE_APP_FRONTEND;

export default createStore({
  modules: {
    auth,
    api,
    notifications,
    media,
    entry,
    translations,
    career,
    newsflash,
    mail,
    files,
  },
  state: {
    menustate: false,
  },
  mutations: {},
  actions: {
    search(context: any, payload: { filter: string; locale: 'de' | 'en' }) {
      return context.dispatch('API', { url: `search/${payload.locale}`, method: 'post', data: { filter: payload.filter }, skipAuth: true }).then((res: APIResult) => {
        return res.data.message;
      });
    },
  },
  getters: {
    getAPIBase() {
      return APIURL;
    },
    getHomeBase() {
      return FRONTENDURL;
    },
  },
});
