const media = {
  state: {},
  mutations: {},
  actions: {
    fetchNewsflash(context: any) {
      return context.dispatch('API', { url: 'newsflash', method: 'get' }).then((res: any) => {
        return res.data.message;
      });
    },
    insertNewsflash(context: any, data: any) {
      return context.dispatch('API', { url: 'newsflash', method: 'post', data });
    },
    updateNewsflash(context: any, data: any) {
      return context.dispatch('API', { url: `newsflash/${data.id}`, method: 'patch', data });
    },
    updateNewsflashOrder(context: any, data: any) {
      return context.dispatch('API', { url: 'newsflash/order', method: 'patch', data });
    },
    deleteNewsflash(context: any, id: number) {
      return context.dispatch('API', { url: `newsflash/${id}`, method: 'delete' });
    },
  },
  getters: {},
};

export default media;
