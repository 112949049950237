<style lang="scss">
#backend {
  nav {
    padding-bottom: 30px;
    position: relative;
    z-index: 11;

    .close-sitebar {
      display: none;
      @media (max-width: $width-xl) {
        display: block;
        cursor: pointer;
        text-align: center;
        position: relative;
        width: 100%;
        padding: 15px 10px;
        span {
          display: inline-block;
          height: 25px;
          line-height: auto !important;
          text-align: center;
          transition: all 400ms ease;
          //border:1px solid red;
          svg {
            width: 25px;
            height: 25px;
            position: relative;
            top: -2px;
            //border:1px solid blue;
          }
        }
        &:after {
          content: '';
          display: block;
          width: 90%;
          position: absolute;
          left: 5%;
          bottom: 0;
          height: 1px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #fff;
          display: none;
        }
        &:hover {
          span {
            transform: rotate(180deg);
            transform-origin: center center;
          }
        }
      }
    }
    #logo {
      display: block;
      max-width: 140px;
      margin: 30px auto 30px auto;
    }
    .profile-box {
      //border:1px solid red;
      margin-bottom: 10px;
      .profile-img {
        width: 120px;
        height: 120px;
        margin: 20px auto;
        img {
          display: block;
          width: 100%;
          border-radius: 50%;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        }
      }
      .profile-name {
        text-align: center;
        margin-bottom: 20px;
        //font-family:"Helvetica Neue LT W05 56 Italic";
        font-style: italic;
        font-weight: 300;
        font-size: 90%;
      }
      .whitespace-line {
        max-width: 80%;
        margin: 0 auto;
        height: 1px;
        background: none;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #fff;
      }
    }
    .test-btn-all {
      margin-bottom: 10px;
      background: #f9f9f9;
      .space {
        width: 1px;
        background: none;
        border-right: 1px solid #edecec;
        border-left: 1px solid #fff;
      }
      .test-btn {
        display: block;
        color: #212121;
        padding: 10px 15px;
        font-size: 90%;
        position: relative;
        left: 0;
        transition: all 200ms ease;
        &:hover {
          &:first-child {
            left: -10px;
          }
          &:last-child {
            left: 10px;
          }
        }
      }
    }
    .navi-link {
      display: block;
      //padding:1rem 1.25rem;
      padding: 1rem 2.5rem;
      text-align: left;
      color: $link-color-dark;
      font-size: 90%;
      letter-spacing: 1px;
      &:hover {
        opacity: 0.8;
      }
    }
    .sub-navi-link {
      display: block;
      text-align: left;
      //padding:.8rem 2rem;
      padding: 0.8rem 3rem;
      color: $link-color-dark;
      font-size: 90%;
      letter-spacing: 1px;
      &:hover {
        opacity: 0.8;
      }
    }
    .accordion-navi {
      border: 0px solid red;

      .accordion-button {
        border: 0;
        border-radius: 0;
        font-size: 0.9rem;
        padding: 1rem 2.5rem;
        font-family: 'Helvetica Neue LT W05 65 Medium';
        letter-spacing: 1px;
        &:hover {
          opacity: 0.8;
        }
        &:after {
          background-image: none;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #666;
        }
        &:hover {
          &:after {
            border-top: 5px solid $font-color-dark;
          }
        }
        &:focus {
          border-color: none;
          box-shadow: none;
        }
        &:not(.collapsed) {
          background: none;
          color: $font-color-dark;
          &:after {
            border-top: 5px solid $font-color-dark;
          }
        }
      }
      .accordion-collapse {
        border: 0;
        border-radius: 0;
        .accordion-body {
          background: #f9f9f9;
          padding: 0;
          ul {
            li {
              text-align: left;
            }
          }
        }
      }
    }
    .accordion-profil {
      margin-bottom: 10px;
      //border:1px solid red;
      .accordion-button {
        //border:1px solid blue;
        padding: 1rem 2.5rem;
      }
      .accordion-body {
        .sub-navi-link {
          padding: 0.8rem 3rem;
        }
      }
    }
  }
}
</style>

<template>
  <nav>
    <div class="close-sitebar" @click="toggleMenu">
      <span><Icon :path="mdiClose"/></span>
    </div>
    <div id="logo">
      <router-link :to="{ name: 'AdminHome' }"><img src="@/assets/img/styria-media-group-logo.svg" alt="Kontakt" class="img-fluid img-logo"/></router-link>
    </div>
    <div class="profile-box">
      <div class="whitespace-line"></div>
      <div class="profile-img "><img src="@/assets/img/avatar-profil.jpg" alt="Avatar empty" class="img-fluid" /></div>
      <div class="accordion accordion-navi accordion-profil">
        <div class="accordion-item">
          <div class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#profil-accor" aria-expanded="false" aria-controls="profil-accor">{{ user.firstname }} {{ user.lastname }}</button>
          </div>
          <div id="profil-accor" class="accordion-collapse collapse" aria-labelledby="headingTwo">
            <div class="accordion-body">
              <!--<router-link :to="{ name: 'Profile' }" class="sub-navi-link"><del>Profil</del></router-link>-->
              <router-link :to="{ name: 'ChangePassword' }" class="sub-navi-link">Passwort ändern</router-link>
              <a href="#" class="router-link-active sub-navi-link" @click="logout">Ausloggen</a>
            </div>
          </div>
        </div>
      </div>
      <div class="whitespace-line"></div>
    </div>
    <router-link :to="{ name: 'Home' }" target="_blank" class="navi-link">Startseite // Frontend</router-link>
    <!--<router-link :to="{ name: 'vorschau-devices' }" class="navi-link"><del>Vorschau // Devices</del></router-link>-->

    <div class="accordion accordion-navi" id="test01">
      <div class="accordion-item">
        <div class="accordion-header" id="headingOne" v-hasperm="'entry.see'">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#testOne" aria-expanded="false" aria-controls="testOne">
            Eintrag
          </button>
        </div>
        <div id="testOne" class="accordion-collapse collapse" aria-labelledby="headingOne">
          <div class="accordion-body">
            <router-link :to="{ name: 'OverviewEntry' }" class="sub-navi-link" v-hasperm="'entry.see'">Übersicht</router-link>
            <router-link :to="{ name: 'CreateEntry' }" class="sub-navi-link" v-hasperm="'entry.edit'">Erstellen</router-link>
            <!--<router-link :to="{ name: 'AuthorOverview' }" class="sub-navi-link"><del>Authoren</del></router-link>
            <router-link :to="{ name: 'CategoryKeywords' }" class="sub-navi-link"><del>Kategorien / Keywords</del></router-link>-->
          </div>
        </div>
      </div>
    </div>
    <router-link :to="{ name: 'MediaLib' }" class="navi-link" v-hasperm="'media.see'">Medien Bibliothek</router-link>
    <router-link :to="{ name: 'Newsflash' }" class="navi-link" v-hasperm="'newsflash.see'">Newsflash</router-link>
    <router-link :to="{ name: 'CareerOverview' }" class="navi-link" v-hasperm="'career.see'">Karriere</router-link>
    <router-link :to="{ name: 'LanguageVariables' }" class="navi-link" v-hasperm="'i18n.see'">Sprachen / Text</router-link>

    <!-- <div class="d-flex justify-content-center align-self-center test-btn-all">
      <router-link :to="{ name: 'Home' }" target="_blank" class="test-btn">Startseite</router-link>
      <div class="space"></div>
      <router-link :to="{ name: 'AdminHome' }" class="test-btn">Backend</router-link>
    </div> -->
    <!--<div class="accordion accordion-navi" id="test02">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#testTwo" aria-expanded="false" aria-controls="testTwo">
            Accordion 2
          </button>
        </h2>
        <div id="testTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
          <div class="accordion-body">
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 1</router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 2</router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 3</router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 4 </router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 5</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion accordion-navi" id="test03">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#testThree" aria-expanded="false" aria-controls="testThree">
            Accordion 3
          </button>
        </h2>
        <div id="testThree" class="accordion-collapse collapse" aria-labelledby="headingThree">
          <div class="accordion-body">
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 1</router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 2</router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 3</router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 4 </router-link>
            <router-link :to="{ name: 'AdminHome' }" class="sub-navi-link">Test-Link 5</router-link>
          </div>
        </div>
      </div>
    </div>
    -->
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { mdiClose } from '@mdi/js';

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentRoute = ref(router.currentRoute);
    const menu = computed(() => store.state.menustate);
    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    const logout = () => {
      store.dispatch('logout');
    };

    const user = computed(() => {
      return store.getters.accountInfo;
    });

    return {
      logout,
      user,
      menu,
      currentRoute,
      toggleMenu,
      mdiClose,
    };
  },
});
</script>
