<style lang="scss">
#frontend {
  .sitebar-navigation {
    height: 100vh;
    width: 0px; /* 0 width - change this with JavaScript */
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    overflow-x: hidden;
    transition: all 200ms ease;
    -moz-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    scrollbar-width: thin;
    scrollbar-color: #ccc $grey;
    //padding-bottom:50px;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      outline: 1px solid $grey;
      border-radius: 5px;
    }
    .close-sitebar {
      display: none;
      @media (max-width: 2550px) {
        display: block;
        width: 100%;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 100;
        background: #fff;
        padding: 10px;
        cursor: pointer;
        span {
          color: #000;
          font-size: 150%;
        }
      }
    }
    .logo-sitebar {
      width: 100%;
      height: 100px;
      margin-bottom: 10px;
      a {
        display: block;

        position: relative;
        top: 50%;
        transform: translateY(-50%);
        &:hover {
          background:none;
        }
      }
      .router-link-active {
        background:none;
      }
      img {
        display: block;
        width: 50%;
        margin: 15px auto;
      }
    }
    .inner-nav {
      //border:1px solid red;
      padding-bottom: 150px;
      opacity: 0;
    }
    .accordion-navi {
      .accordion-button {
        border: 0;
        padding: 1rem 1.25rem;
        display: flex;
        font-size: 90%;
        letter-spacing: 1px;
        &:hover {
          background: $grey;
        }
        &:after {
          display: none;
        }
        &:focus,
        &:active {
          box-shadow: none;
        }
        .txt-link {
          width: 100%;
        }
        .icon {
          width: 30px;
          transition: all 200ms ease;
          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
      .accordion-button:not(.collapsed) {
        background: $grey;
        color: #000;
        .icon {
          transform: rotate(-180deg);
        }
      }
      .accordion-collapse {
        border: 0;
        .accordion-body {
          padding: 0.5rem;
          border-bottom: 5px solid $sand;
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
              .dropdown-header {
                padding: 0.5rem;
                text-transform: uppercase;
                color: #212529;
                font-family: 'Helvetica Neue LT W05 65 Medium';

                letter-spacing: 1px;
              }
              transition: all 200ms ease;
              //border-bottom:1px solid $grey;
              &:last-child {
                border-bottom: 0;
              }
              .dropdown-item {
                padding: 10px;
                letter-spacing: 1px;
                font-size: 90%;
                &:active,
                &:focus {
                  background: transparent;
                  color: #000;
                }

                &:hover {
                  background: $grey;
                }
              }
              .router-link-active {
                //border:1px solid red;
                background: $sand;
                &:hover {
                  opacity: 1;
                  background: $sand;
                }
              }
              .dropdown-item-sub {
                padding-left: 25px;
                position: relative;
                //border:1px solid red;
                &:before {
                  content: '-';
                  display: inline-block;
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-55%);
                }
              }
              &:hover {
              }
            }
          }
        }
      }
    }
    .sitebar-navi-link {
      padding: 1rem 1.25rem;
      font-size: 90%;
      letter-spacing: 1px;
      color: #000;
      display: block;
      &:hover {
        background: $grey;
      }
    }
    
    .router-link-excat-active {
      background: $sand;
        &:hover {
          opacity: 1;
          background: $sand;
        }
    }
    
    .social-box {
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(238, 238, 238, 0) 100%);
      //border:1px solid red;
      position: relative;
      //border:1px solid red;
      bottom: 0px;
      left: 0;
      padding-top: 0px;
      padding-bottom: 0px;
      //border:1px solid red;
      display: flex;
      z-index: 5;

      .social-box-inner {
        width: 72.5px;
        height: 72.5px;
        text-align: center;
        position: relative;

        //border:1px solid green;
        &:after {
          content: '';
          display: table;
          clear: both;
        }
        a {
          //border:1px solid red;
          display: inline-block;
          line-height: 72.5px;

          img {
            width: 28px;
            transition: all 200ms ease;
          }
          &:hover {
            img {
              transform: scale(0.8);
            }
          }
        }
      }
      .bg-color-linked {
        background: $blue;
      }
      .bg-color-insta {
        background: $coral;
      }
      .bg-color-fb {
        background: $fb-violet;
      }
      .bg-color-youtube {
        background: $wine-red;
      }
      .clear {
        clear: both;
      }
      .pos-right {
        text-align: right;
      }
      .pos-left {
        text-align: left;
      }
    }

    .language-box {
      display: none;
      text-align: center;
      @media (max-width: 992px) {
        display: block;
      }

      .language-inner {
        display: inline-block;
        position: relative;
        top: 3px;
        line-height: 13px;
        font-size: 14px;
        padding: 0px 15px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
          color:#000;
        }
      }
      .router-link-active {
        background:none;
        font-family: 'Helvetica Neue LT W05 65 Medium';
      }
      .lang-de {
        border-right: 1px solid #000;
      }
    }
    .button-top {
      a {
        display: block;
        text-align: left;
        color: #fff;
        padding: 1rem 1.25rem;

        letter-spacing: 2px;
        background: $blue;
        img {
          width: 20px;
          margin-left: 7px;
          position: relative;
          top: -1px;
        }
        &:hover {
          opacity: 0.7;
          background: $blue;
        }
      }
    }
  }
  .sitebar-navigation.open {
    width: 290px;
    .inner-nav {
      transition: opacity 400ms ease-in-out;
      opacity: 1;
    }
    .social-box {
      //border:2px solid blue;
      position: fixed;
    }
    .socialbox-fadein {
      animation-name: fadeInSocial;
      animation-duration: 2s;

      @keyframes fadeInSocial {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    .ul-sidenav {
    }
  }
  .test-links {
    display: flex;
    a {
      display: block;
      flex: 1 0 50%;
      color: #000;
      text-align: center;
      font-size: 12px;
      background: $grey-darker;
      padding: 10px;
    }
  }
}
</style>

<template>
  <div class="sitebar-navigation" :class="{ open }">
    <div @click="emitter.emit('toggleSidebar')" class="close-sitebar"><span>&times;</span></div>
    <div class="language-box">
      <router-link tag="div" class="language-inner lang-de" :to="{ params: { locale: 'de' } }">DE</router-link>
      <router-link tag="div" class="language-inner lang-en" :to="{ params: { locale: 'en' } }">EN</router-link>
    </div>
    <div class="logo-sitebar">
      <router-link :to="{ name: 'Home' }">
        <img src="@/assets/img/styria-media-group-logo-v3.svg" alt="Kontakt" class="img-fluid" />
      </router-link>
    </div>
    <!--  -->
    <!--<div class="test-links">
     <router-link :to="{ name: 'Home' }" class="test-btn">Startseite</router-link>
    <router-link :to="{ name: 'AdminHome' }" class="test-btn">Backend</router-link>
    </div>-->
    <!--  -->
    <div class="inner-nav">
      <div class="button-top link-kontakt">
        <router-link :to="{ name: 'kontakt' }" class=""
          >{{$t('General.kontakt')}} <span class="icon-img"><img src="@/assets/img/header-kontakt-icon-white.svg" alt="Kontakt" class="img-fluid"/></span
        ></router-link>
      </div>
      <div class="button-top link-suche">
        <router-link :to="{ name: 'suche' }" class=""
          >{{$t('General.suche')}} <span class="icon-img"><img src="@/assets/img/header-suche-icon-white.svg" alt="Kontakt" class="img-fluid"/></span
        ></router-link>
      </div>
      <!--<router-link :to="{ name: 'TestSite' }" class="sitebar-navi-link">Test-Site</router-link>-->
      <router-link :to="{ name: 'Home' }" class="sitebar-navi-link sitebar-navi-link-startseite">{{$t('General.startseite')}}</router-link>
      <div class="accordion accordion-navi">
        <div class="accordion-item">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#brands-link" aria-expanded="false" aria-controls="brands-link">
            <span class="txt-link">{{$t('General.brands')}}</span><span class="icon"><Icon :path="mdiChevronDown"/></span>
          </button>
          <div id="brands-link" class="accordion-collapse collapse">
            <div class="accordion-body">
              <ul>
                <li><router-link :to="{ name: 'media' }" class="dropdown-item">{{$t('General.media')}}</router-link></li>
                <li><router-link :to="{ name: 'marketplaces-uebersicht' }" class="dropdown-item">{{$t('General.marketplaces')}}</router-link></li>
                <!--<li><router-link :to="{ name: 'marketplaces-ueber-uns' }" class="dropdown-item dropdown-item-sub">{{$t('General.ueber-uns')}}</router-link></li>
                <li><router-link :to="{ name: 'marketplaces-marken' }" class="dropdown-item dropdown-item-sub">{{$t('General.marken')}}</router-link></li>
                
                <li><router-link :to="{ name: 'marketplaces-startup-ventures' }" class="dropdown-item dropdown-item-sub" v-html="$t('General.startup-ventures')"></router-link></li>
                <li><router-link :to="{ name: 'marketplaces-karriere' }" class="dropdown-item dropdown-item-sub">{{$t('General.karriere')}}</router-link></li>
                <li><router-link :to="{ name: 'marketplacesnews' }" class="dropdown-item dropdown-item-sub">{{$t('General.news')}}</router-link></li>-->
                <li><router-link :to="{ name: 'service-partner' }" class="dropdown-item">{{$t('General.service-partner')}}</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion accordion-navi">
        <div class="accordion-item">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ueberuns-link" aria-expanded="false" aria-controls="ueberuns-link">
            <span class="txt-link">{{$t('General.ueber-uns')}}</span><span class="icon"><Icon :path="mdiChevronDown"/></span>
          </button>
          <div id="ueberuns-link" class="accordion-collapse collapse">
            <div class="accordion-body">
              <ul>
                <li><router-link :to="{ name: 'was-uns-ausmacht' }" class="dropdown-item">{{$t('General.was-uns-ausmacht')}}</router-link></li>
                <li><router-link :to="{ name: 'wo-wir-sind' }" class="dropdown-item">{{$t('General.wo-wir-sind')}}</router-link></li>
                <li><router-link :to="{ name: 'management' }" class="dropdown-item">{{$t('General.management')}}</router-link></li>
                <li><router-link :to="{ name: 'geschichte' }" class="dropdown-item">{{$t('General.geschichte')}}</router-link></li>
                <li><router-link :to="{ name: 'corporate-social-responsibility' }" class="dropdown-item">{{$t('General.corporate-social-responsibility')}}</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion accordion-navi">
        <div class="accordion-item">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#karriere-link" aria-expanded="false" aria-controls="karriere-link">
            <span class="txt-link">Karriere</span><span class="icon"><Icon :path="mdiChevronDown"/></span>
          </button>
          <div id="karriere-link" class="accordion-collapse collapse">
            <div class="accordion-body">
              <ul>
                <li><router-link :to="{ name: 'as-good-as-our-people' }" class="dropdown-item">{{$t('General.as-good-as-our-people')}}</router-link></li>
                <li><router-link :to="{ name: 'berufs-bilder' }" class="dropdown-item">{{$t('General.berufsbilder')}}</router-link></li>
                <li><router-link :to="{ name: 'offene-positionen' }" class="dropdown-item">{{$t('General.offene-positionen')}}</router-link></li>
                <!--<li><a href="https://karriere.styria.com" target="_blank" class="dropdown-item">{{$t('General.offene-positionen')}}</a></li>-->
                <li><router-link :to="{ name: 'talentepool' }" class="dropdown-item">{{$t('General.talentepool')}}</router-link></li>
                <li><router-link :to="{ name: 'styrianerIn-werden' }" class="dropdown-item" v-html="$t('General.styrianner-werden')"></router-link></li>
                <li><router-link :to="{ name: 'karriere-weiterbildung' }" class="dropdown-item" v-html="$t('General.karriere-weiterbildung')"></router-link></li>
                <li><router-link :to="{ name: 'lehre-praktika' }" class="dropdown-item" v-html="$t('General.lehre-praktika')"></router-link></li>
                <li><router-link :to="{ name: 'karriere-kontakt' }" class="dropdown-item">{{$t('General.ansprechpartnerinnen')}}</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'service' }" class="sitebar-navi-link">{{$t('General.service')}}</router-link>
      <div class="accordion accordion-navi">
        <div class="accordion-item">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#newsroom-link" aria-expanded="false" aria-controls="newsroom-link">
            <span class="txt-link">Newsroom</span><span class="icon"><Icon :path="mdiChevronDown"/></span>
          </button>
          <div id="newsroom-link" class="accordion-collapse collapse">
            <div class="accordion-body">
              <ul>
                <li><router-link :to="{ name: 'news' }" class="dropdown-item">{{$t('General.news')}}</router-link></li>
                <!--<li><router-link :to="{ name: 'presskit' }" class="dropdown-item">{{$t('General.presskit')}}</router-link></li>-->
                <li><router-link :to="{ name: 'kontakt' }" class="dropdown-item">{{$t('General.kontakt')}}</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
   
      <div class="social-box socialbox-fadein">
        <div class="social-box-inner bg-color-linked">
          <a href="https://at.linkedin.com/company/styria-medien-ag" target="_blank"><img src="@/assets/img/icon-linkedin.svg" alt="Linked In" class="img-fluid"/></a>
        </div>
        <div class="social-box-inner bg-color-insta">
          <a href="https://www.instagram.com/styriamediagroup/" target="_blank"><img src="@/assets/img/icon-instagram.svg" alt="Instagram" class="img-fluid"/></a>
        </div>
        <div class="social-box-inner bg-color-fb">
          <a href="https://www.facebook.com/styriamediagroup" target="_blank"><img src="@/assets/img/icon-facebook.svg" alt="Facebook" class="img-fluid"/></a>
        </div>
        <div class="social-box-inner bg-color-youtube">
          <a href="https://www.youtube.com/channel/UCIeEj9CidJZY6ymknOAAtaA" target="_blank"><img src="@/assets/img/icon-youtube.svg" alt="Youtube" class="img-fluid"/></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Emitter } from 'mitt';
import { defineComponent, computed, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { mdiChevronDown } from '@mdi/js';

export default defineComponent({
  setup() {
    const router = useRouter();
    const currentRoute = ref(router.currentRoute);
    const open = ref(false);
    const emitter = inject('emitter') as Emitter;

    emitter.on('toggleSidebar', () => {
      open.value = !open.value;
    });

    emitter.on('closeSidebar', () => {
      open.value = false;
    });

    return {
      emitter,
      open,
      currentRoute,
      mdiChevronDown,
    };
  },
});
</script>
