
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();

    const currentRoute = ref(router.currentRoute);

    return {
      currentRoute,
    };
  },
});
