<style lang="scss">
#frontend {
  $height-qua-big: 300px;
  $height-qua-small: 250px;
  .karriere-startseite {
    padding-bottom: 150px;
    .img-box {
      background-size: cover;
    }
    .img-v1 {
      height: $height-qua-big;
      width: 100%;
      background-position: center center;
      @media (max-width: 1400px) and (min-width: 1200px) {
        height: $height-qua-small;
      }
      @media (max-width: $width-md) {
        height: 400px;
      }
    }
    .txt-box {
      margin-top: 20px;
      letter-spacing: 1px;
      h3 {
        font-size: 130%;
        margin-top: 55px;
        margin-bottom: 20px;
      }
      p {
        line-height: 1.5;
      }
    }

    .img-v2 {
      height: 600px;
      width: 100%;
      background-position: center center;
    }
    .img-v3 {
      height: $height-qua-big;
      width: 100%;
      background-position: center center;
    }
    .img-v4 {
      height: 300px;
      width: 100%;
      background-position: center center;
      @media (max-width: 1400px) and (min-width: 1200px) {
        height: $height-qua-small;
      }
    }
    .color-box {
      height: $height-qua-big;
      width: 100%;
      background: $sand;
      text-align: center;
      padding: 30px 20px;
      @media (max-width: 1400px) and (min-width: 1200px) {
        height: $height-qua-small;
      }
      @media (max-width: $width-lg) {
        height: auto;
      }
      .txt-v1 {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 150%;
        margin-bottom: 70px;
        @media (max-width: 1400px) {
          margin-bottom: 60px;
        }
        @media (max-width: $width-lg) {
          margin-bottom: 100px;
        }
      }
      .txt-v2 {
        letter-spacing: 1px;
      }
    }
    .position-minus {
      position: relative;
      top: 15px;

      @media (max-width: $width-xl) {
        top: 0;
      }
    }
    .row-padding {
      @media (max-width: $width-xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      @media (max-width: $width-lg) {
        padding-bottom: 0;
      }
    }
    .space-width-l {
      @media (max-width: $width-lg) {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
      }
    }
    .btn-std {
      margin-top: 80px;
    }
  }
}
</style>

<template>
  <div class="karriere-startseite">
    <div class="container">
      <div class="row header-section-box justify-content-center align-self-center">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">{{$t('Home.karriere')}}</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1 v-html="$t('Home.karriere-title')"></h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-self-center ">
        <div class="col-xl-3">
          <div class="row">
            <div class="col-xl-12 col-lg-6 col-md-6 opacity-0" v-inview="['animation-delay-0-5s', 'scale-in']">
              <div class="img-box img-v1" :style="{ 'background-image': `url(${require('@/assets/img/startseite/karriere-v1.jpg')})` }"></div>
            </div>
            <div class="col-xl-12 col-lg-6 col-md-6">
              <div class="txt-box" v-inview="['animation-delay-1-5s', 'move-in-from-left']">
                <h3 v-html="$t('Home.karriere-title-v2')"></h3>
                <p>{{$t('Home.karriere-text')}}</p>
                <div class="btn-filled"><router-link :to="{ name: 'offene-positionen' }">{{$t('Home.karriere-btn')}}</router-link></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9">
          <div class="row align-items-end justify-content-center row-padding">
            <div class="col-lg-8 col-md-8" v-inview="['animation-delay-1s', 'scale-in']">
              <div class="img-box img-v2" :style="{ 'background-image': `url(${require('@/assets/img/startseite/karriere-v2.jpg')})` }"></div>
            </div>
            <div class="col-lg-4 col-md-8" v-inview="['animation-delay-1-5s', 'scale-in']">
              <div class="img-box img-v3 space-width-l" :style="{ 'background-image': `url(${require('@/assets/img/startseite/karriere-v3.jpg')})` }"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- -->
      <div class="row justify-content-center align-self-center position-minus">
        <div class="col-xl-3"></div>
        <div class="col-xl-9">
          <div class="row justify-content-center align-items-end">
            <div class="col-lg-8 col-md-8" v-inview="['animation-delay-2s', 'scale-in']">
              <div class="img-box img-v4" :style="{ 'background-image': `url(${require('@/assets/img/startseite/karriere-v5.jpg')})` }" style="background-position:bottom center;"></div>
            </div>
            <div class="col-lg-4 col-md-6 space-width-l" v-inview="['animation-delay-2-5s', 'scale-in']">
              <div class="color-box">
                <div class="txt-v1" v-html="$t('Home.karriere-we-are-as-good')"></div>
                <div class="txt-v2" v-html="$t('Home.karriere-unser-human')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="btn-std">
            <router-link :to="{ name: 'styrianerIn-werden' }"
              ><span class="txt">{{$t('Home.mehr-karriere')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
