
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { mdiChevronDoubleLeft } from '@mdi/js';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const i18n = useI18n();

    const path = computed(() => `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`);
    const currentRoute = ref(router.currentRoute);

    return {
      path,
      currentRoute,
      mdiChevronDoubleLeft,
    };
  },
});
