<style lang="scss" scoped>
#backend {
  .subheader {
    //border:1px solid red;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    display: none;
    @media (max-width: 1200px) {
      margin-top: 70px;
      background: #f1f1f1;
    }
    .subheader-controls {
      //border:1px solid red;
      padding-left: 20px;
      @media (max-width: 576px) {
        padding-left: 0;
      }
      .subheasder-btn {
        //border:1px solid blue;
        color: #888;
        @media (max-width: 576px) {
          padding-left: 0;
          text-align: center;
        }
        span {
          display: inline-block;
          padding-right: 10px;
          font-size: 90%;
          letter-spacing: 1px;
          strong {
            padding-left: 5px;
          }
        }
        svg {
          width: 25px;
          fill: #888;
        }
        &:hover {
          cursor: pointer;
          color: #333;
          svg {
            fill: #333;
          }
        }
      }
    }
    .preview-box {
      display: inline-block;
      padding: 20px 15px;
      text-align: right;
      justify-content: flex-end;
      display: flex;
      align-content: center;
      @media (max-width: $width-xl) {
        padding: 10px 15px;
      }
      @media (max-width: 576px) {
        //border:1px solid red;
        text-align: center;
        justify-content: center;
        padding: 0 10px;
      }
      .preview-txt {
        //border:1px solid red;
        color: #888;

        padding: 0 10px;

        letter-spacing: 1px;
        font-size: 90%;
      }
      .preview-btn {
        width: 25px;
        margin-left: 10px;
        cursor: pointer;

        svg {
          max-width: 100%;
          fill: #888;
        }
        &:hover {
          svg {
            fill: #333;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="subheader">
    <div class="row">
      <div class="col-sm-6 justify-content-center align-self-center">
        <div class="subheader-controls">
          <div class="subheasder-btn">
            <span>Beitrag ist<strong>öffentlich</strong></span
            ><!--<Icon :path="mdiEyeOff " />--><Icon :path="mdiEye" />
          </div>
        </div>
      </div>
      <div class="col-sm-6 justify-content-center align-self-center">
        <div class="preview-box">
          <div class="preview-txt">Vorschau</div>
          <div class="preview-btn"><Icon :path="mdiDesktopMac" /></div>
          <div class="preview-btn"><Icon :path="mdiTabletAndroid" /></div>
          <div class="preview-btn"><Icon :path="mdiCellphone" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiDesktopMac, mdiTabletAndroid, mdiCellphone, mdiEyeOff, mdiEye } from '@mdi/js';

export default defineComponent({
  setup() {
    return {
      mdiDesktopMac,
      mdiTabletAndroid,
      mdiCellphone,
      mdiEyeOff,
      mdiEye,
    };
  },
});
</script>
