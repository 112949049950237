
import { defineComponent, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { Emitter } from 'mitt';
import { mdiShareVariantOutline } from '@mdi/js';

export default defineComponent({
  setup() {
    const router = useRouter();
    const currentRoute = ref(router.currentRoute);
    const emitter = inject('emitter') as Emitter;
    const open = ref(false);

    emitter.on('toggleSidebar', () => {
      open.value = !open.value;
    });

    emitter.on('closeSidebar', () => {
      open.value = false;
    });

    document.addEventListener('scroll', () => {
      const headerClassAdd = document.getElementById('header');
      if (!headerClassAdd) return;
      if (document.documentElement.scrollTop > 180) {
        headerClassAdd.classList.add('scroll-header');
      } else {
        headerClassAdd.classList.remove('scroll-header');
      }
    });

    return {
      emitter,
      currentRoute,
      open,
      mdiShareVariantOutline,
    };
  },
});
