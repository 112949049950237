<style lang="scss" scoped>
.box {
  //box-shadow:0 .3rem 1rem rgba(0,0,0,.10);
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.02), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.02), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.03), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.02);
  padding: 20px 10px;
  background: #fff;

  //border-radius:.5rem;
  margin: 12px;
  .box-inner {
    justify-content: center;
    align-self: center;
    align-items: stretch;

    span {
      display: block;
      text-align: center;
    }
    .nr {
      font-size: 140%;
      font-weight: 900;
      letter-spacing: 1px;
    }
    .txt {
      font-size: 90%;
    }
    &:nth-child(1) {
    }
    &:nth-child(2) {
    }
  }
}
</style>

<template>
  <div>
    <div class="box">
      <div class="box-inner">
        <span class="nr">{{ nr }}</span>
      </div>
      <div class="box-inner">
        <span class="txt">
          <slot></slot>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    nr: String,
  },
});
</script>
