import { RouteRecordRaw } from 'vue-router';

import FrontendContainer from '../views/FrontendContainer.vue';
import FEIndex from '../views/Frontend/Index.vue';

export const fronendroutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'de',
  },
  {
    path: '/marktplaetze',
    redirect: '/de/marketplaces/marketplaces-uebersicht/',
  },
  {
    path: '/marketplaces',
    redirect: '/en/marketplaces/marketplaces-uebersicht/',
  },
  {
    path: '/:locale',
    component: FrontendContainer,
    children: [
      { path: '', name: 'Home', component: FEIndex },
      { path: 'testsite', name: 'testsite', component: () => import(/* webpackChunkName: "TestSite" */ '../views/Frontend/TestSite.vue') },
      { path: 'test2', name: 'test2', component: () => import(/* webpackChunkName: "TestSite" */ '../views/Frontend/Test2.vue') },
      { path: 'suche', name: 'suche', component: () => import(/* webpackChunkName: "Suche" */ '../views/Frontend/Suche.vue') },
      { path: 'marketplaces/marketplaces-uebersicht', name: 'marketplaces-uebersicht', component: () => import(/* webpackChunkName: "Marketplaces/MarketplacesOverview" */ '../views/Frontend/Marketplaces/MarketplacesOverview.vue') },
      { path: 'marketplaces/marketplaces-ueber-uns', name: 'marketplaces-ueber-uns', component: () => import(/* webpackChunkName: "Marketplaces/MarketplacesUeberUns" */ '../views/Frontend/Marketplaces/MarketplacesUeberUns.vue') },
      { path: 'marketplaces/marketplaces-marken', name: 'marketplaces-marken', component: () => import(/* webpackChunkName: "Marketplaces/MarketplacesMarken" */ '../views/Frontend/Marketplaces/MarketplacesMarken.vue') },
      { path: 'marketplaces/marketplaces-technology', name: 'marketplaces-technology', component: () => import(/* webpackChunkName: "Marketplaces/MarketplacesTechnology" */ '../views/Frontend/Marketplaces/MarketplacesTechnology.vue') },
      { path: 'marketplaces/marketplaces-startup-ventures', name: 'marketplaces-startup-ventures', component: () => import(/* webpackChunkName: "Marketplaces/MarketplacesStartupVentures" */ '../views/Frontend/Marketplaces/MarketplacesStartupVentures.vue') },
      { path: 'marketplaces/marketplaces-karriere', name: 'marketplaces-karriere', component: () => import(/* webpackChunkName: "Marketplaces/MarketplacesKarriere" */ '../views/Frontend/Marketplaces/MarketplacesKarriere.vue') },

      { path: 'karriere/as-good-as-our-people', name: 'as-good-as-our-people', component: () => import(/* webpackChunkName: "Karriere/AsGoodAsOurPeople" */ '../views/Frontend/Karriere/AsGoodAsOurPeople.vue') },
      { path: 'karriere/karriere-weiterbildung', name: 'karriere-weiterbildung', component: () => import(/* webpackChunkName: "Karriere/KarriereWeiterbildung" */ '../views/Frontend/Karriere/KarriereWeiterbildung.vue') },
      { path: 'karriere/traumjob-gesucht', name: 'offene-positionen', component: () => import(/* webpackChunkName: "Karriere/OffenePositionen" */ '../views/Frontend/Karriere/OffenePositionen.vue') },
      { path: 'karriere/styrianerIn-werden', name: 'styrianerIn-werden', component: () => import(/* webpackChunkName: "Karriere/StyrianerWerden" */ '../views/Frontend/Karriere/StyrianerWerden.vue') },
      { path: 'karriere/berufs-bilder', name: 'berufs-bilder', component: () => import(/* webpackChunkName: "Karriere/BerufsBilder" */ '../views/Frontend/Karriere/BerufsBilder.vue') },
      { path: 'karriere/lehre-praktika', name: 'lehre-praktika', component: () => import(/* webpackChunkName: "Karriere/LehrePraktika" */ '../views/Frontend/Karriere/LehrePraktika.vue') },
      { path: 'karriere/talentepool', name: 'talentepool', component: () => import(/* webpackChunkName: "Karriere/Talentepool" */ '../views/Frontend/Karriere/Talentepool.vue') },
      { path: 'karriere/karriere-kontakt', name: 'karriere-kontakt', component: () => import(/* webpackChunkName: "Karriere/Kontakt" */ '../views/Frontend/Karriere/Kontakt.vue') },
      { path: 'ueber-uns/was-uns-ausmacht', name: 'was-uns-ausmacht', component: () => import(/* webpackChunkName: "WasUnsAusmacht" */ '../views/Frontend/UeberUns/WasUnsAusmacht.vue') },
      { path: 'ueber-uns/wo-wir-sind', name: 'wo-wir-sind', component: () => import(/* webpackChunkName: "WoWirSind" */ '../views/Frontend/UeberUns/WoWirSind.vue') },
      { path: 'ueber-uns/management', name: 'management', component: () => import(/* webpackChunkName: "Management" */ '../views/Frontend/UeberUns/Management.vue') },
      { path: 'ueber-uns/geschichte', name: 'geschichte', component: () => import(/* webpackChunkName: "Geschichte" */ '../views/Frontend/UeberUns/Geschichte.vue') },
      { path: 'ueber-uns/corporate-social-responsibility', name: 'corporate-social-responsibility', component: () => import(/* webpackChunkName: "CorporateSocialResponsibility" */ '../views/Frontend/UeberUns/CorporateSocialResponsibility.vue') },
      { path: 'service', name: 'service', component: () => import(/* webpackChunkName: "Service" */ '../views/Frontend/Service.vue') },

      { path: 'kontakt', name: 'kontakt', component: () => import(/* webpackChunkName: "Kontakt" */ '../views/Frontend/Kontakt.vue') },
      { path: 'brands/media', name: 'media', component: () => import(/* webpackChunkName: "Media" */ '../views/Frontend/Brands/Media.vue') },
      { path: 'brands/service-partner', name: 'service-partner', component: () => import(/* webpackChunkName: "ServicePartner" */ '../views/Frontend/Brands/ServicePartner.vue') },
      { path: 'brands/willhaben', name: 'willhaben', component: () => import(/* webpackChunkName: "Brands/BrandWillhaben" */ '../views/Frontend/Brands/BrandWillhaben.vue') },
      { path: 'brands/njuskalo', name: 'njuskalo', component: () => import(/* webpackChunkName: "Brands/BrandNjuskalo" */ '../views/Frontend/Brands/BrandNjuskalo.vue') },
      { path: 'brands/bolha', name: 'bolha', component: () => import(/* webpackChunkName: "Brands/BrandBolha" */ '../views/Frontend/Brands/BrandBolha.vue') },
      { path: 'brands/mojedelo', name: 'mojedelo', component: () => import(/* webpackChunkName: "Brands/BrandMojedelo" */ '../views/Frontend/Brands/BrandMojedelo.vue') },
      { path: 'brands/trikoder', name: 'trikoder', component: () => import(/* webpackChunkName: "Brands/BrandTrikoder" */ '../views/Frontend/Brands/BrandTrikoder.vue') },
      { path: 'brands/njuskalo-technology', name: 'njuskalo-technology', component: () => import(/* webpackChunkName: "Brands/BrandNjuskaloTechnology" */ '../views/Frontend/Brands/BrandNjuskaloTechnology.vue') },
      { path: 'brands/meinbezirk', name: 'meinbezirk', component: () => import(/* webpackChunkName: "Brands/BrandMeinBezirk" */ '../views/Frontend/Brands/BrandMeinBezirk.vue') },
      { path: 'brands/meinmed', name: 'meinmed', component: () => import(/* webpackChunkName: "Brands/BrandMeinMed" */ '../views/Frontend/Brands/BrandMeinMed.vue') },
      { path: 'brands/hausaerztin', name: 'hausaerztin', component: () => import(/* webpackChunkName: "Brands/BrandHausaerztin" */ '../views/Frontend/Brands/BrandHausaerztin.vue') },
      { path: 'brands/mein-sonntag', name: 'mein-sonntag', component: () => import(/* webpackChunkName: "Brands/BrandMeinSonntag" */ '../views/Frontend/Brands/BrandMeinSonntag.vue') },
      { path: 'brands/leben', name: 'leben', component: () => import(/* webpackChunkName: "Brands/BrandLeben" */ '../views/Frontend/Brands/BrandLeben.vue') },
      { path: 'brands/car4you', name: 'car4you', component: () => import(/* webpackChunkName: "Brands/BrandCar4you" */ '../views/Frontend/Brands/BrandCar4you.vue') },
      { path: 'brands/ballguide', name: 'ballguide', component: () => import(/* webpackChunkName: "Brands/BrandBallguide" */ '../views/Frontend/Brands/BrandBallguide.vue') },
      { path: 'brands/kneipp-verlag', name: 'kneipp-verlag', component: () => import(/* webpackChunkName: "Brands/BrandKneippVerlag" */ '../views/Frontend/Brands/BrandKneippVerlag.vue') },
      { path: 'brands/molden-verlag', name: 'molden-verlag', component: () => import(/* webpackChunkName: "Brands/BrandMoldenVerlag" */ '../views/Frontend/Brands/BrandMoldenVerlag.vue') },
      { path: 'brands/styria-verlag', name: 'styria-verlag', component: () => import(/* webpackChunkName: "Brands/BrandStyriaVerlag" */ '../views/Frontend/Brands/BrandStyriaVerlag.vue') },
      { path: 'brands/pichler-verlag', name: 'pichler-verlag', component: () => import(/* webpackChunkName: "Brands/BrandPichlerVerlag" */ '../views/Frontend/Brands/BrandPichlerVerlag.vue') },
      { path: 'brands/die-presse', name: 'die-presse', component: () => import(/* webpackChunkName: "Brands/BrandDiePresse" */ '../views/Frontend/Brands/BrandDiePresse.vue') },
      { path: 'brands/futter', name: 'futter', component: () => import(/* webpackChunkName: "Brands/BrandFutter" */ '../views/Frontend/Brands/BrandFutter.vue') },
      { path: 'brands/ich-koche', name: 'ich-koche', component: () => import(/* webpackChunkName: "Brands/BrandichKoche" */ '../views/Frontend/Brands/BrandichKoche.vue') },
      { path: 'brands/ligaportal', name: 'ligaportal', component: () => import(/* webpackChunkName: "Brands/BrandLigaportal" */ '../views/Frontend/Brands/BrandLigaportal.vue') },
      { path: 'brands/miss', name: 'miss', component: () => import(/* webpackChunkName: "Brands/BrandMiss" */ '../views/Frontend/Brands/BrandMiss.vue') },
      { path: 'brands/bezirksblaetter', name: 'bezirksblaetter', component: () => import(/* webpackChunkName: "Brands/BrandBezirksblaetter" */ '../views/Frontend/Brands/BrandBezirksblaetter.vue') },
      { path: 'brands/wiener-bezirkszeitung', name: 'wiener-bezirkszeitung', component: () => import(/* webpackChunkName: "Brands/BrandWienerBezirkszeitung" */ '../views/Frontend/Brands/BrandWienerBezirkszeitung.vue') },
      { path: 'brands/der-grazer', name: 'der-grazer', component: () => import(/* webpackChunkName: "Brands/BrandDerGrazer" */ '../views/Frontend/Brands/BrandDerGrazer.vue') },
      { path: 'brands/woche', name: 'woche', component: () => import(/* webpackChunkName: "Brands/BrandWoche" */ '../views/Frontend/Brands/BrandWoche.vue') },
      { path: 'brands/die-furche', name: 'die-furche', component: () => import(/* webpackChunkName: "Brands/BrandFurche" */ '../views/Frontend/Brands/BrandFurche.vue') },
      { path: 'brands/kleine-zeitung', name: 'kleine-zeitung', component: () => import(/* webpackChunkName: "Brands/BrandKleineZeitung" */ '../views/Frontend/Brands/BrandKleineZeitung.vue') },
      { path: 'brands/radio-flamingo', name: 'radio-flamingo', component: () => import(/* webpackChunkName: "Brands/BrandRadioFlamingo" */ '../views/Frontend/Brands/BrandRadioFlamingo.vue') },
      { path: 'brands/antenne-kaernten', name: 'antenne-kaernten', component: () => import(/* webpackChunkName: "Brands/BrandAntenneKaernten" */ '../views/Frontend/Brands/BrandAntenneKaernten.vue') },
      { path: 'brands/antenne-steiermark', name: 'antenne-steiermark', component: () => import(/* webpackChunkName: "Brands/BrandAntenneSteiermark" */ '../views/Frontend/Brands/BrandAntenneSteiermark.vue') },
      { path: 'brands/radio-content-austria', name: 'radio-content-austria', component: () => import(/* webpackChunkName: "Brands/BrandRadioContentAustria" */ '../views/Frontend/Brands/BrandRadioContentAustria.vue') },
      { path: 'brands/styria-it-solutions', name: 'styria-it-solutions', component: () => import(/* webpackChunkName: "Brands/BrandStyriaITSolutions" */ '../views/Frontend/Brands/BrandStyriaITSolutions.vue') },
      { path: 'brands/styria-marketing-services', name: 'styria-marketing-services', component: () => import(/* webpackChunkName: "Brands/BrandStyriaMarketingServices" */ '../views/Frontend/Brands/BrandStyriaMarketingServices.vue') },
      { path: 'brands/styria-digital-development', name: 'styria-digital-development', component: () => import(/* webpackChunkName: "Brands/BrandStyriaDigitalDevelopment" */ '../views/Frontend/Brands/BrandStyriaDigitalDevelopment.vue') },
      { path: 'brands/styria-digital-services', name: 'styria-digital-services', component: () => import(/* webpackChunkName: "Brands/BrandStyriaDigitalServices" */ '../views/Frontend/Brands/BrandStyriaDigitalServices.vue') },
      { path: 'brands/styria-media-design', name: 'styria-media-design', component: () => import(/* webpackChunkName: "Brands/BrandStyriaMediaDesign" */ '../views/Frontend/Brands/BrandStyriaMediaDesign.vue') },

      { path: 'brands/druck-styria', name: 'druck-styria', component: () => import(/* webpackChunkName: "Brands/BrandDruckStyria" */ '../views/Frontend/Brands/BrandDruckStyria.vue') },
      { path: 'brands/druck-carinthia', name: 'druck-carinthia', component: () => import(/* webpackChunkName: "Brands/BrandDruckCarinthia" */ '../views/Frontend/Brands/BrandDruckCarinthia.vue') },

      { path: 'brands/call-and-mail', name: 'call-and-mail', component: () => import(/* webpackChunkName: "Brands/BrandCallMail" */ '../views/Frontend/Brands/BrandCallMail.vue') },
      { path: 'brands/styria-fides', name: 'styria-fides', component: () => import(/* webpackChunkName: "Brands/BrandStyriaFides" */ '../views/Frontend/Brands/BrandStyriaFides.vue') },
      { path: 'brands/diva', name: 'diva', component: () => import(/* webpackChunkName: "Brands/BrandDiva" */ '../views/Frontend/Brands/BrandDiva.vue') },
      { path: 'brands/poslovni-dnevnik', name: 'poslovni-dnevnik', component: () => import(/* webpackChunkName: "Brands/BrandPoslovniDnevnik" */ '../views/Frontend/Brands/BrandPoslovniDnevnik.vue') },
      { path: 'brands/kleine-kinderzeitung', name: 'kleine-kinderzeitung', component: () => import(/* webpackChunkName: "Brands/BrandKinderzeitung" */ '../views/Frontend/Brands/BrandKinderzeitung.vue') },
      { path: 'brands/kleine-pausenzeitung', name: 'kleine-pausenzeitung', component: () => import(/* webpackChunkName: "Brands/BrandPausenzeitung" */ '../views/Frontend/Brands/BrandPausenzeitung.vue') },
      { path: 'brands/wienerin', name: 'wienerin', component: () => import(/* webpackChunkName: "Brands/BrandWienerin" */ '../views/Frontend/Brands/BrandWienerin.vue') },
      { path: 'brands/sport-aktiv', name: 'sport-aktiv', component: () => import(/* webpackChunkName: "Brands/BrandSportAktiv" */ '../views/Frontend/Brands/BrandSportAktiv.vue') },
      { path: 'brands/connect724', name: 'connect724', component: () => import(/* webpackChunkName: "Brands/BrandConnect724" */ '../views/Frontend/Brands/BrandConnect724.vue') },
      { path: 'brands/gesund-at', name: 'gesund-at', component: () => import(/* webpackChunkName: "Brands/BrandGesundAt" */ '../views/Frontend/Brands/BrandGesundAt.vue') },
      { path: 'brands/cope', name: 'cope', component: () => import(/* webpackChunkName: "Brands/BrandCope" */ '../views/Frontend/Brands/BrandCope.vue') },
      { path: 'brands/redmail', name: 'redmail', component: () => import(/* webpackChunkName: "Brands/BrandRedmail" */ '../views/Frontend/Brands/BrandRedmail.vue') },
      { path: 'brands/24sata', name: '24sata', component: () => import(/* webpackChunkName: "Brands/Brand24sata" */ '../views/Frontend/Brands/Brand24sata.vue') },
      { path: 'brands/vecernji-list', name: 'vecernji-list', component: () => import(/* webpackChunkName: "Brands/BrandVecernjiList" */ '../views/Frontend/Brands/BrandVecernjiList.vue') },
      { path: 'brands/express', name: 'express', component: () => import(/* webpackChunkName: "Brands/BrandExpress" */ '../views/Frontend/Brands/BrandExpress.vue') },
      { path: 'brands/joomboos', name: 'joomboos', component: () => import(/* webpackChunkName: "Brands/BrandJoomBoos" */ '../views/Frontend/Brands/BrandJoomBoos.vue') },
      { path: 'brands/miss7gastro', name: 'miss7gastro', component: () => import(/* webpackChunkName: "Brands/BrandMiss7Gastro" */ '../views/Frontend/Brands/BrandMiss7Gastro.vue') },
      { path: 'brands/miss7mama', name: 'miss7mama', component: () => import(/* webpackChunkName: "Brands/BrandMiss7Mama" */ '../views/Frontend/Brands/BrandMiss7Mama.vue') },
      { path: 'brands/miss7zdrava', name: 'miss7zdrava', component: () => import(/* webpackChunkName: "Brands/BrandMiss7zDrava" */ '../views/Frontend/Brands/BrandMiss7zDrava.vue') },
      { path: 'brands/pixsell', name: 'pixsell', component: () => import(/* webpackChunkName: "Brands/BrandPixsell" */ '../views/Frontend/Brands/BrandPixsell.vue') },
      { path: 'brands/tiskara-zagreb', name: 'tiskara-zagreb', component: () => import(/* webpackChunkName: "Brands/BrandTiskaraZagreb" */ '../views/Frontend/Brands/BrandTiskaraZagreb.vue') },
      { path: 'brands/zurnal24', name: 'zurnal24', component: () => import(/* webpackChunkName: "Brands/BrandZurnal24" */ '../views/Frontend/Brands/BrandZurnal24.vue') },
      { path: 'brands/med-over-net', name: 'med-over-net', component: () => import(/* webpackChunkName: "Brands/BrandMedOverNet" */ '../views/Frontend/Brands/BrandMedOverNet.vue') },

      { path: 'brands/adriasonara', name: 'adriasonara', component: () => import(/* webpackChunkName: "Brands/BrandWoche" */ '../views/Frontend/Brands/BrandAdriasonara.vue') },
      { path: 'brands/pharmonia', name: 'pharmonia', component: () => import(/* webpackChunkName: "Brands/BrandWoche" */ '../views/Frontend/Brands/BrandPharmonia.vue') },

      { path: 'impressum', name: 'impressum', component: () => import(/* webpackChunkName: "Impressum" */ '../views/Frontend/Footer/Impressum.vue') },
      { path: 'offenlegung', name: 'offenlegung', component: () => import(/* webpackChunkName: "Offenlegung" */ '../views/Frontend/Footer/Offenlegung.vue') },

      //{ path: 'datenschutz', name: 'datenschutz', component: () => import(/* webpackChunkName: "Datenschutz" */ '../views/Frontend/Footer/Datenschutz.vue') },
      { path: 'presskit', name: 'presskit', component: () => import(/* webpackChunkName: "Presskit" */ '../views/Frontend/Footer/Presskit.vue') },
      { path: '404', name: '404', component: () => import(/* webpackChunkName: "404" */ '../views/Frontend/404.vue') },
      { path: '403', name: '403', component: () => import(/* webpackChunkName: "403" */ '../views/Frontend/403.vue') },
      { path: '500', name: '500', component: () => import(/* webpackChunkName: "500" */ '../views/Frontend/500.vue') },
      // { path: 'newsite', name: 'NeueSeite', component: () => import(/* webpackChunkName: "NeueSeite" */ '../views/Frontend/NeueSeite.vue') },

      { path: 'news', name: 'news', component: () => import(/* webpackChunkName: "News" */ '../views/Frontend/News.vue') },
      { path: 'news/:id', name: 'newsdetails', component: () => import(/* webpackChunkName: "EntryView" */ '../views/Frontend/EntryView.vue') },
      { path: 'news/keyword/:keyword', name: 'newskeyword', component: () => import(/* webpackChunkName: "News" */ '../views/Frontend/NewsKeyword.vue') },
      { path: 'marketplaces/news', name: 'marketplacesnews', component: () => import(/* webpackChunkName: "EntryView" */ '../views/Frontend/Marketplaces/MarketplacesNews.vue') },
      { path: 'marketplaces/news/:id', name: 'marketplacesnewsdetail', component: () => import(/* webpackChunkName: "EntryView" */ '../views/Frontend/EntryView.vue') },
    ],
  },
];
