
import { defineComponent, ref, computed, PropType } from 'vue';
import { mdiCheck } from '@mdi/js';

type MultiSelectItem = {
  label: string;
  value: any;
};

export default defineComponent({
  props: {
    items: { type: Array as PropType<Array<MultiSelectItem>>, default: () => [] },
    placeholder: { type: String, default: 'Bitte wählen' },
    modelValue: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const container = ref<HTMLElement>();
    const open = ref(false);
    const model = computed({
      get: () => props.modelValue,
      set: (value: any) => emit('update:modelValue', value),
    });

    // Events
    const onButtonClick = () => {
      container.value?.focus();
      open.value = !open.value;
    };

    const onItemClick = (value: any) => {
      if (model.value.indexOf(value) > -1) {
        model.value.splice(model.value.indexOf(value), 1);
      } else {
        model.value.push(value);
      }
    };

    const openerText = computed(() => {
      if (model.value.length > 0) {
        let text = '';
        for (const index in model.value) {
          const label = props.items.find((item: MultiSelectItem) => item.value == model.value[index])?.label || model.value[index];
          text = text == '' ? `${label}` : `${text}, ${label}`;
        }

        return text;
      } else {
        return props.placeholder;
      }
    });

    const containerBlur = () => (open.value = false);

    return {
      open,
      model,
      onButtonClick,
      onItemClick,
      openerText,
      containerBlur,
      container,
      mdiCheck,
    };
  },
});
