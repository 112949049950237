// const parsePayload = (payload: Entry) => {
//   return {
//     title: payload.title,
//     thumbnail: payload.thumbnail,
//     author: payload.author,
//     introtext: payload.introtext,
//     categories: JSON.stringify(payload.categories),
//     keywords: JSON.stringify(payload.keywords.toString().split(',')),
//     publishdate: payload.publishdate,
//     rows: JSON.stringify(payload.rows),
//   };
// };

// const parseResult = (res: any) => {
//   const entry = res;

//   res.rows = JSON.parse(res.rows);
//   res.keywords = JSON.parse(res.keywords);
//   res.categories = JSON.parse(res.categories);
//   res.publishdate = new Date(res.publishdate);

//   return entry;
// };

const entry = {
  state: {},
  mutations: {},
  actions: {
    deleteEntry(context: any, id: number) {
      return context.dispatch('API', { method: 'delete', url: `entry/${id}` });
    },
    saveEntry(context: any, payload: i18nEntry) {
      console.log(payload.publishdate);
      // const data: { id?: number; en?: any; de: any } = {
      //   id: payload.id,
      //   de: parsePayload(payload.de),
      // };

      // if (payload.en) data.en = parsePayload(payload.en);

      if (payload.id) {
        return context.dispatch('API', { url: `entry/${payload.id}`, method: 'patch', data: payload }).then((res: any) => {
          return { id: payload.id };
        });
      } else {
        return context.dispatch('API', { url: 'entry', method: 'post', data: payload }).then((res: any) => {
          return res.data.message;
        });
      }
    },
    fetchEntry(context: any, id: number) {
      return context.dispatch('API', { url: `entry/${id}` }).then((res: any) => {
        // const entries: i18nEntry = {
        //   id: res.data.message.id,
        //   de: parseResult(res.data.message.de),
        // };
        // if (res.data.message.en) {
        //   entries.en = parseResult(res.data.message.en);
        // }

        return res.data.message;
      });
    },
    fetchEntries(context: any) {
      return context.dispatch('API', { url: `entry` }).then((res: any) => {
        return res.data.message.map((item: string) => {
          // item.de = parseResult(item.de);
          // if (item.en) item.en = parseResult(item.en);

          return item;
        });
      });
    },
    fetchEntriesForCategory(context: any, id: number) {
      return context.dispatch('API', { url: `entry/category/${id}` }).then((res: any) => {
        return res.data.message;
      });
    },
    fetchCategories(context: any) {
      return context.dispatch('API', { url: 'categories' }).then((res: any) => {
        return res.data.message;
      });
    },
  },
  getters: {},
};

export default entry;
