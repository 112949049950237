const APIURL = process.env.VUE_APP_API;

const media = {
  state: {
    medialib: [],
  },
  mutations: {
    setMediaLib(state: any, payload: any) {
      state.medialib = payload.map((item: MediaEntity) => {
        item.url = `${APIURL}${item.url}`;
        return item;
      });
    },
  },
  actions: {
    fetchEntity(context: any, id: number) {
      return context.dispatch('API', { url: `medialib/${id}`, method: 'get', skipAuth: true }).then((res: any) => {
        res.data.message.url = `${APIURL}${res.data.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaLib(context: any) {
      return context.dispatch('API', { url: 'medialib', method: 'get' }).then((res: any) => {
        context.commit('setMediaLib', res.data.message);
      });
    },
    uploadMedia(context: any, file: File) {
      const data = new FormData();
      data.append('file', file);
      return context.dispatch('API', { url: 'medialib', method: 'post', data, headers: { 'Content-Type': 'multipart/form-data' } });
    },
    uploadCategorizedMedia(context: any, payload: { category: string; file: File }) {
      const data = new FormData();
      data.append('file', payload.file);
      return context.dispatch('API', { url: `medialib/${payload.category}`, method: 'post', data, headers: { 'Content-Type': 'multipart/form-data' } });
    },
    removeMedia(context: any, id: number) {
      return context.dispatch('API', { url: `medialib/${id}`, method: 'delete' });
    },
    updateMedia(context: any, payload: MediaEntity) {
      return context.dispatch('API', { url: `medialib/${payload.id}`, method: 'patch', data: payload });
    },
  },
  getters: {
    getMediaLib(state: any) {
      return state.medialib;
    },
  },
};

export default media;
