<style lang="scss">
#backend {
  footer {
    border-top: 1px dashed #eee;
    padding: 20px;
    margin-top: 50px;
    .created {
      display: block;
      text-align: center;
      font-size: 11px;
      letter-spacing: 2px;
      color: #666;
    }
  }
}
</style>

<template>
  <footer>
    <div class="container-fluid">
      <div class="row justify-content-center align-self-center">
        <div class="col-12">
          <span class="created">created | styria media design 2021</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
