<style lang="scss">
.top-info {
  .bgv1 {
    .box {
    }
  }
}
.content-box {
  margin-top: 15px;
  margin-bottom: 15px;
  .content-box-inner {
    background: #fff;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.02), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.02), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.03), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.02);
    .content-box-headline {
      padding: 10px;
      border-bottom: 1px solid #eee;
      h2 {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 0;
        padding: 10px 5px;
      }
    }
    .artikel {
      //border-bottom:1px solid #f1f1f1;

      margin: 0px;
      padding: 10px 5px;

      &:last-child {
        border-bottom: 0;
      }
      .bild-box {
        width: 100%;
        height: 150px;
        background: #212121;
        @media (max-width: $width-sm) {
          margin-bottom: 20px;
        }
      }
      .art-title {
        border: 0px solid red;
        h3 {
          font-size: 1.2rem;
        }
      }
      .art-txt {
        p {
          margin-bottom: 0;
        }
      }
      .art-date {
        align-self: center;
        text-align: right;
        font-size: 90%;
      }
      &:hover {
        background: #eee;
        cursor: pointer;
      }
    }
  }
}
</style>

<template>
  <div class="container-fluid test">
    <div class="top-info row">
      <InfoBox nr="29.3 ms" class="col-xl-3 col-lg-4 col-md-6 justify-content-center align-self-center bgv1">API Response Time </InfoBox>
      <InfoBox nr="34.234 MB" class="col-xl-3 col-lg-4 col-md-6 justify-content-center align-self-center bgv2">Mediendaten</InfoBox>
      <InfoBox nr="35 / 2354" class="col-xl-3 col-lg-4 col-md-6 justify-content-center align-self-center bgv3">User &empty; Monat / Jahr</InfoBox>
      <InfoBox nr="29.3 ms" class="col-xl-3 col-lg-4 col-md-6 justify-content-center align-self-center bgv1">API Response Time</InfoBox>
      <InfoBox nr="29.3 ms" class="col-xl-4 col-lg-4 col-md-6 justify-content-center align-self-center bgv1">API Response Time</InfoBox>
      <InfoBox nr="34.234 MB" class="col-xl-4 col-lg-4 col-md-6 justify-content-center align-self-center bgv2">Mediendaten</InfoBox>
      <InfoBox nr="35 / 2354" class="col-xl-4 col-lg-4 col-md-6 justify-content-center align-self-center bgv3">User &empty; Monat / Jahr</InfoBox>
    </div>
    <div class="row">
      <div class="col-lg-12 content-box">
        <div class="content-box-inner">
          <div class="content-box-headline row g-0">
            <div class="col-10 justify-content-end align-self-center">
              <h2>Top Beiträge</h2>
            </div>
            <div class="col-2 justify-content-end align-self-center text-end">
              <div class="dropdown-std">
                <button class="dropdown-toggle" type="button" id="dropdownMenuButton-test01" data-bs-toggle="dropdown" aria-expanded="false">
                  asdf
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton-test01">
                  <li><a class="dropdown-item" href="#">Test 01</a></li>
                  <li><a class="dropdown-item" href="#">Test 02</a></li>
                  <li><a class="dropdown-item" href="#">Test 03</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="collapse" id="collapseBox01" :class="{ show }">
            <div class="artikel row">
              <div class="col-lg-2 col-sm-4 justify-content-center align-self-start">
                <div class="bild-box"></div>
              </div>
              <div class="col-lg-10 col-sm-8 justify-content-center align-self-center">
                <div class="row">
                  <div class="col-12 art-title">
                    <h3>Lorem ipsum dolor sit amet</h3>
                  </div>
                  <div class="col-12 art-txt">
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                      tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="artikel row">
              <div class="col-lg-2 col-sm-4 justify-content-center align-self-start">
                <div class="bild-box"></div>
              </div>
              <div class="col-lg-10 col-sm-8 justify-content-center align-self-center">
                <div class="row">
                  <div class="col-12 art-title">
                    <h3>Lorem ipsum dolor sit amet</h3>
                  </div>
                  <div class="col-12 art-txt">
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                      tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="artikel row">
              <div class="col-lg-2 col-sm-4 justify-content-center align-self-start">
                <div class="bild-box"></div>
              </div>
              <div class="col-lg-10 col-sm-8 justify-content-center align-self-center">
                <div class="row">
                  <div class="col-12 art-title">
                    <h3>Lorem ipsum dolor sit amet</h3>
                  </div>
                  <div class="col-12 art-txt">
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                      tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 content-box">
        <div class="content-box-inner">
          <div class="content-box-headline row g-0">
            <div class="col-10 justify-content-end align-self-center">
              <h2>Letzten Beiträge</h2>
            </div>
            <div class="col-2 justify-content-end align-self-center text-end">
              <div class="dropdown-std">
                <button class="dropdown-toggle" type="button" id="dropdownMenuButton-test01" data-bs-toggle="dropdown" aria-expanded="false">
                  asdf
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton-test01">
                  <li><a class="dropdown-item" href="#">Test 01</a></li>
                  <li><a class="dropdown-item" href="#">Test 02</a></li>
                  <li><a class="dropdown-item" href="#">Test 03</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 content-box">
        <div class="content-box-inner">
          <div class="content-box-headline row g-0">
            <div class="col-10 justify-content-end align-self-cente">
              <h2>Letzten Karriere-Einträge</h2>
            </div>
            <div class="col-2 justify-content-end align-self-center text-end">
              <div class="dropdown-std">
                <button class="dropdown-toggle" type="button" id="dropdownMenuButton-test01" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="material-icons settings-icon">settings</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton-test01">
                  <li><a class="dropdown-item" href="#">Test 01</a></li>
                  <li><a class="dropdown-item" href="#">Test 02</a></li>
                  <li><a class="dropdown-item" href="#">Test 03</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 content-box">
        <div class="content-box-inner">
          <div class="content-box-headline">
            <div class="col-12">
              <h2>Letzten Beiträge</h2>
            </div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
          <div class="artikel row">
            <div class="col-8 art-title">Lorem ipsum dolor sit amet, consetetur sadipscing</div>
            <div class="col-4 art-date">12.03.2021</div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 content-box">
        <div class="content-box-inner">
          <div class="content-box-headline">
            <div class="col-12">
              <h2>?!?!?!</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { defineComponent } from 'vue';
import InfoBox from '../../components/Backend/InfoBox.vue';

export default defineComponent({
  components: {
    InfoBox,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    if (store.getters.hasPerm('entry.see')) {
      router.push({ name: 'OverviewEntry' });
    } else if (store.getters.hasPerm('i18n.see')) {
      router.push({ name: 'LanguageVariables' });
    }
  },
});
</script>
