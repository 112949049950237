<style lang="scss">
#frontend {
  .zielgruppen-box-startseite {
    position: relative;
    padding-bottom: 150px;

    [class*='col-'] {
      //border:1px solid red;
    }
    .bg-color {
      background: $violet-50per;
      text-align: center;
      padding: 90px 30px 140px 30px;
      color: #fff;
      font-size: 180%;
      position: relative;
      @media (max-width: $width-lg) {
        font-size: 100%;
      }
      @media (max-width: $width-md) {
        padding: 50px 40px;
      }
      .nr-box {
        .nr {
          font-family: 'Helvetica Neue LT W05 25 Ult Lt';
          display: inline-block;
          font-size: 600%;
          margin-right: 5px;
          font-weight: 300;
          letter-spacing: 2px;
          position: relative;

          line-height: 1;
          @media (max-width: $width-md) {
            font-size: 500%;
          }
          .stern {
            display: inline-block;
            font-size: 30%;
            position: absolute;
            right: -40px;
            top: 0;
            line-height: 1;
            font-family: 'American Typewriter ITC W04 Md';
            @media (max-width: $width-lg) {
              right: -20px;
            }
          }
        }
      }
      .txt-box {
        margin-top: 50px;
        font-family: 'American Typewriter ITC W04 Md';
        letter-spacing: 1px;

        @media (max-width: $width-md) {
          padding: 0 20px;
        }
        .breaker {
          display: block;
          height: 1px;
          @media (max-width: $width-md) {
            display: none;
          }
        }
      }
    }
    .btn-std {
      margin-top: 100px;
    }
  }
}
</style>

<template>
  <div class="zielgruppen-box-startseite" v-inview="startcountdown">
    <div class="container">
      <div class="row header-section-box justify-content-center align-self-center">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">{{$t('Home.werbeloesungen')}}</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1 v-html="$t('Home.zielgruppen-title')"></h1>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-color">
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-8 col-md-8">
          <div class="nr-box">
            <span class="nr">{{ counterNumber }} <span class="stern">*</span></span>
          </div>
          <div class="txt-box" v-html="$t('Home.zielgruppen-txt')"></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="btn-std">
            <router-link :to="{ name: 'service' }"
              ><span class="txt">{{$t('Home.werbeloesungen-btn')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();
    const counterNumber = ref(53694);
    let interval = 0;
    let count = false;

    const countDown = () => {
      if (counterNumber.value >= 10) {
        counterNumber.value = counterNumber.value - (Math.floor(Math.random() * 5) + 75);
      } else {
        counterNumber.value = 0;
        clearInterval(interval);
      }
    };

    const startcountdown = () => {
      if (!count) {
        count = true;
        interval = setInterval(countDown, 1);
      }
    };

    return {
      counterNumber,
      startcountdown,
    };
  },
});
</script>
