
import { defineComponent, inject } from 'vue';
import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxStart from '../../components/Frontend/HeaderImageBoxStart.vue';
import NewsFlash from '../../components/Frontend/NewsFlash.vue';
import MarkenBoxenStartseite from '../../components/Frontend/MarkenBoxenStartseite.vue';
import UeberUnsBox from '../../components/Frontend/UeberUnsBox.vue';
import KarriereBox from '../../components/Frontend/KarriereBox.vue';
import NewsBox from '../../components/Frontend/NewsBox.vue';
import AboBox from '../../components/Frontend/AboBox.vue';
import ZielgruppenBox from '../../components/Frontend/ZielgruppenBox.vue';
import FooterStartseite from '../../components/Frontend/FooterStartseite.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'Styria Media Group. One spirit – unlimited ideas.',
      keywords: ['media company', 'styria' , 'medien' , 'graz' , 'styria media group'],
      description: 'Die STYRIA ist einer der führenden Medienkonzerne in Österreich, Kroatien und Slowenien. Orientierung geben, Vertrauen schaffen, Gemeinschaft fördern.',
      image: require('@/assets/img/og/startseite.png'),
      
    },
    en: {
      title: 'Styria Media Group. One spirit – unlimited ideas.',
      keywords: ['medienkonzern', 'styria' , 'medien' , 'graz' , 'styria media group'],
      description: 'STYRIA is one of the leading media groups in Austria, Croatia and Slovenia. Providing orientation, creating trust, promoting community.',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    HeaderImageBoxStart,
    NewsFlash,
    MarkenBoxenStartseite,
    UeberUnsBox,
    KarriereBox,
    NewsBox,
    AboBox,
    ZielgruppenBox,
    FooterStartseite,
  },
});
