<style lang="scss">
$width-sitebar: 320px;
#backend {
  //background:#f9fafb;
  #backToTop {
    background-color: rgba(0, 0, 0, 0.15);
    transition: all 200ms ease;
    position: fixed;
    opacity: 0;
    width: 40px;
    height: 40px;
    right: 10px;
    bottom: 10px;
    z-index: 100;
    a {
      display: block;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      position: relative;
      left: -1px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      
      .backtotop-icon {
        &:after {
        
        }
      }
    }
  }
  #sitebar-box-shadow {
    display: none;
    position: fixed;
    width: 290px;
    height: 60%;
    //transform:rotate(-90deg);
    top: 20%;
    z-index: 1;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  #sitebar {
    position: fixed;
    z-index: 1000;
    width: $width-sitebar;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc #eee;
    border-right: 1px solid #eee;
    background: #f1f1f1;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 20px;
      border: 4px solid #eee;
    }

    @media (max-width: $width-xl) {
      left: -380px;
      transition: left 0.3s;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      &.open {
        left: 0;
      }
    }
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  #wrapper-content {
    position: relative;
    margin-left: $width-sitebar;
    //overflow: hidden;
    @media (max-width: $width-xl) {
      margin-left: 0;
    }
    .wrapper {
      position: relative;
      padding: 50px 15px;
      //height:1500px;
      max-width: 1320px;
      margin: 0 auto;
      @media (max-width: $width-xl) {
        //margin-top:50px;
        padding-top: 100px;
      }
    }
  }
}
</style>

<template>
  <div id="backend">
    <a name="top" id="top"></a>
    <div id="backToTop">
      <a href="#top" v-smooth-scroll><Icon :path="mdiChevronUp"/></a>
    </div>
    <div id="sitebar-box-shadow"></div>
    <div id="sitebar" :class="{ open: menustate }">
      <Navigation class="navigation" />
    </div>
    <div id="wrapper-content">
      <Header />
      <SubHeader />
      <div class="wrapper">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
  <Notification />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import Navigation from '../components/Backend/Navigation.vue';
import Header from '../components/Backend/Header.vue';
import SubHeader from '../components/Backend/SubHeader.vue';
import Footer from '../components/Backend/Footer.vue';
import Notification from '../components/Notification.vue';
import { useStore } from 'vuex';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  components: {
    Navigation,
    Header,
    SubHeader,
    Footer,
    Notification,
  },
  setup() {
    document.addEventListener('scroll', () => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });

    const store = useStore();
    const menustate = computed(() => store.state.menustate);
    return {
      menustate,
      mdiChevronUp,
    };
  },
});
</script>
