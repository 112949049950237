<style lang="scss" scoped>
.entry {
  &.col-lg-4 {
    margin-bottom: 30px;
  }
  cursor: pointer;
}

.slider-outer-news {
  //border:1px solid yellow;
  padding-top: 50px;
  position: relative;

  //border:1px solid blue;
  .slidercontainer {
    //border:1px solid green;

    .slideritem {
      //border:1px solid red;
      padding: 15px 30px;
      .news-item {
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: $width-md) {
          top: 0;
          transform: translateY(0%);
        }
      }
    }
    .arrow-all {
      position: absolute;
      height: 50px;
      //border:1px solid red;
      width: 50px;
      top: 0;
      cursor: pointer;
      img {
        display: block;
        max-width: 100%;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .arrow-left {
      left: 20%;
      @media (max-width: $width-md) {
        left: 10%;
      }
    }
    .arrow-right {
      right: 20%;
      @media (max-width: $width-md) {
        right: 10%;
      }
    }
  }
}
</style>

<template>
  <div class="container-fluid">
    <!--- NO SLIDER --->
    <div class="row" v-if="!slider && paged && pages > 1">
      <Pagination v-model="page" :pages="pages" />
    </div>
    <div class="row justify-content-center" v-if="!slider && filteresentries.length > 0">
      <!-- <router-link :to="{ name: routename, params: { id: entry.id } }" custom v-slot="{ navigate }" > -->
      <div class="col-lg-4 col-md-6 col-sm-8" :key="entry.id" v-for="entry of filteresentries">
        <Entry :entry="entry" :routername="routename" />
      </div>
    </div>
    <div class="row" v-if="!slider && paged && pages > 1">
      <Pagination v-model="page" :pages="pages" />
    </div>

    <!--- SLIDER --->

    <div class="row justify-content-center" v-if="slider && filteresentries.length > 0">
      <div class="slider-outer-news">
        <Slider :delay="0" :items="items" easing="ease-in-out" :speed="1500">
          <template #navigation="{ left, right }">
            <div @click="right" class="arrow-all arrow-left"><img src="@/assets/img/slider-arrow-left.svg" alt="Pfeil Links" class="img-fluid" /></div>
            <div @click="left" class="arrow-all arrow-right"><img src="@/assets/img/slider-arrow-right.svg" alt="Pfeil Rechts" class="img-fluid" /></div>
          </template>
          <template #items>
            <SliderItem :key="entry.id" v-for="entry of filteresentries">
              <!-- <router-link :to="{ name: routename, params: { id: entry.id } }" custom v-slot="{ navigate }"> -->
              <Entry :entry="entry" :routername="routename" />
            </SliderItem>
          </template>
        </Slider>
      </div>
    </div>

    <div class="row" v-if="filteresentries.length <= 0 && showerror">
      <div class="col-12 text-center">
        {{ $t('General.keine-news') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Entry from './Entry.vue';
import Slider from '../Slider.vue';
import SliderItem from '../SliderItem.vue';
import Pagination from '../Pagination.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    Entry,
    Slider,
    SliderItem,
    Pagination,
  },
  props: {
    category: { type: Number },
    routename: { type: String, default: 'newsdetails' },
    keyword: { type: String },
    slider: { type: Boolean, default: false },
    showerror: { type: Boolean, default: false },
    limit: { type: Number, default: -1 },
    paged: { type: Boolean, default: false },
    perpage: { type: Number, default: 18 },
  },
  setup(props) {
    const store = useStore();
    const entries = ref<Array<i18nEntry>>([]);
    const items = ref({ 1200: 3, 768: 2, 0: 1 });
    const i18n = useI18n();

    onMounted(() => {
      console.log('mounted');
      if (props.category) {
        store.dispatch('fetchEntriesForCategory', props.category).then((res: Array<i18nEntry>) => {
          entries.value = res;
          if (entries.value.length < 3) {
            items.value[1200] = 2;
          }

          if (entries.value.length < 2) {
            items.value[1200] = 1;
            items.value[768] = 1;
          }
        });
      } else {
        store.dispatch('fetchEntries').then((res: Array<i18nEntry>) => {
          entries.value = res;
          if (entries.value.length < 3) {
            items.value[1200] = 2;
          }

          if (entries.value.length < 2) {
            items.value[1200] = 1;
            items.value[768] = 1;
          }
        });
      }
    });

    const prepaged = computed(() => {
      let filtered = entries.value;
      const locale = i18n.locale.value as 'de' | 'en';

      filtered = filtered.filter((item) => {
        return item[locale] != null;
      });

      if (props.keyword) {
        filtered = filtered.filter((item) => {
          if (props.keyword && (item.de?.keywords.split(',').includes(props.keyword) || item.en?.keywords.split(',').includes(props.keyword))) {
            return true;
          }

          return false;
        });
      }

      if (props.limit != -1) {
        filtered = filtered.slice(0, props.limit);
      }

      return filtered;
    });

    const page = ref(0);

    const pages = computed(() => Math.ceil(prepaged.value.length / props.perpage));
    const filteresentries = computed(() => {
      if (props.paged) {
        return prepaged.value.slice(page.value * props.perpage, page.value * props.perpage + props.perpage);
      } else {
        return prepaged.value;
      }
    });

    return {
      filteresentries,
      items,
      page,
      pages,
    };
  },
});
</script>
