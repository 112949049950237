
import { defineComponent, ref, PropType } from 'vue';

export default defineComponent({
  props: {
    prefix: {
      type: String,
      default: '',
    },
    text: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    eraseSpeed: {
      type: Number,
      default: 100,
    },
    typeSpeed: {
      type: Number,
      default: 200,
    },
    delay: {
      type: Number,
      default: 2000,
    },
    caret: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const textValue = ref('');
    const arrayIndex = ref(0);
    const charIndex = ref(0);

    let write = function() {
      return;
    };
    let erase = function() {
      return;
    };

    write = () => {
      if (charIndex.value < props.text[arrayIndex.value].length) {
        textValue.value += props.text[arrayIndex.value].charAt(charIndex.value);
        charIndex.value++;
        setTimeout(write, props.typeSpeed);
      } else {
        setTimeout(erase, props.delay);
      }
    };

    erase = () => {
      if (charIndex.value > 0) {
        textValue.value = props.text[arrayIndex.value].substring(0, charIndex.value - 1);
        charIndex.value--;
        setTimeout(erase, props.eraseSpeed);
      } else {
        arrayIndex.value++;
        if (arrayIndex.value >= props.text.length) arrayIndex.value = 0;
        setTimeout(write, props.typeSpeed);
      }
    };

    write();

    return {
      textValue,
    };
  },
});
