
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();
    const counterNumber = ref(53694);
    let interval = 0;
    let count = false;

    const countDown = () => {
      if (counterNumber.value >= 10) {
        counterNumber.value = counterNumber.value - (Math.floor(Math.random() * 5) + 75);
      } else {
        counterNumber.value = 0;
        clearInterval(interval);
      }
    };

    const startcountdown = () => {
      if (!count) {
        count = true;
        interval = setInterval(countDown, 1);
      }
    };

    return {
      counterNumber,
      startcountdown,
    };
  },
});
