import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { backendroutes } from './backend';
import { fronendroutes } from './frontend';
import store from '../store';
import { emitter } from '../emitter';
import { nextTick } from 'vue';

import { loadLocaleMessages, SUPPORT_LOCALES, setI18nLanguage } from '@/i18n';

export const routes: Array<RouteRecordRaw> = [
  ...backendroutes,
  ...fronendroutes,
  {
    path: '/:locale/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  const paramsLocale = to.params.locale as string;

  if (to.params.locale != from.params.locale) {
    emitter.emit('resetSlider');
  }

  if (to.meta.permission) {
    if (!store.getters.hasPerm(to.meta.permission)) {
      return { name: 'Login', replace: true, params: { locale: paramsLocale } };
    }
  }

  if (!store.getters.validToken('accessToken')) {
    store.dispatch('refresh');
  }

  const domain = to.name?.toString() || 'General';

  //use locale if paramsLocale is not in SUPPORT_LOCALES
  if (!SUPPORT_LOCALES.includes(paramsLocale)) {
    return `/${'de'}`;
  }

  await loadLocaleMessages(paramsLocale, domain);

  setI18nLanguage(paramsLocale);

  return true;
});

router.afterEach((to, from) => {
  nextTick(() => {
    emitter.emit('closeSidebar');
  });
});

export default router;
