
import { defineComponent, computed, PropType } from 'vue';
import dayjs from 'dayjs';
import entry from '@/store/entry';
import { mdiTrayMinus } from '@mdi/js';
import { useI18n } from 'vue-i18n';

type i18nEntryWithId = i18nEntry & { id: number };

export default defineComponent({
  props: {
    entry: { type: Object as PropType<i18nEntryWithId>, required: true },
    lang: { type: String as PropType<'en' | 'de'>, default: 'de' },
    click: { type: Object as PropType<(payload: MouseEvent) => void> },
    routename: { type: String, default: 'newsdetails' },
  },

  setup(props) {
    const publishdate = computed(() => {
      if (props.entry.publishdate !== null) {
        return dayjs(props.entry.publishdate)
          .locale(props.lang)
          .format('DD. MMMM YYYY HH:MM');
      } else {
        return '';
      }
    });

    const i18n = useI18n();
    const localentry = computed(() => {
      const locale = i18n.locale.value as 'de' | 'en';
      if (props.entry[locale]) return props.entry[locale];
      return undefined;
    });

    const keywords = computed(() => localentry.value?.keywords.toString().split(','));

    const niceid = computed(() => {
      let id = props.entry.id.toString();
      if (localentry.value) {
        id +=
          '-' +
          localentry.value.title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/-\s*$/, '')
            .replace(/^-\s*/, '');
      }

      return id;
    });

    return {
      publishdate,
      localentry,
      keywords,
      niceid,
    };
  },
});
