
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Entry from './Entry.vue';
import Slider from '../Slider.vue';
import SliderItem from '../SliderItem.vue';
import Pagination from '../Pagination.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    Entry,
    Slider,
    SliderItem,
    Pagination,
  },
  props: {
    category: { type: Number },
    routename: { type: String, default: 'newsdetails' },
    keyword: { type: String },
    slider: { type: Boolean, default: false },
    showerror: { type: Boolean, default: false },
    limit: { type: Number, default: -1 },
    paged: { type: Boolean, default: false },
    perpage: { type: Number, default: 18 },
  },
  setup(props) {
    const store = useStore();
    const entries = ref<Array<i18nEntry>>([]);
    const items = ref({ 1200: 3, 768: 2, 0: 1 });
    const i18n = useI18n();

    onMounted(() => {
      console.log('mounted');
      if (props.category) {
        store.dispatch('fetchEntriesForCategory', props.category).then((res: Array<i18nEntry>) => {
          entries.value = res;
          if (entries.value.length < 3) {
            items.value[1200] = 2;
          }

          if (entries.value.length < 2) {
            items.value[1200] = 1;
            items.value[768] = 1;
          }
        });
      } else {
        store.dispatch('fetchEntries').then((res: Array<i18nEntry>) => {
          entries.value = res;
          if (entries.value.length < 3) {
            items.value[1200] = 2;
          }

          if (entries.value.length < 2) {
            items.value[1200] = 1;
            items.value[768] = 1;
          }
        });
      }
    });

    const prepaged = computed(() => {
      let filtered = entries.value;
      const locale = i18n.locale.value as 'de' | 'en';

      filtered = filtered.filter((item) => {
        return item[locale] != null;
      });

      if (props.keyword) {
        filtered = filtered.filter((item) => {
          if (props.keyword && (item.de?.keywords.split(',').includes(props.keyword) || item.en?.keywords.split(',').includes(props.keyword))) {
            return true;
          }

          return false;
        });
      }

      if (props.limit != -1) {
        filtered = filtered.slice(0, props.limit);
      }

      return filtered;
    });

    const page = ref(0);

    const pages = computed(() => Math.ceil(prepaged.value.length / props.perpage));
    const filteresentries = computed(() => {
      if (props.paged) {
        return prepaged.value.slice(page.value * props.perpage, page.value * props.perpage + props.perpage);
      } else {
        return prepaged.value;
      }
    });

    return {
      filteresentries,
      items,
      page,
      pages,
    };
  },
});
