
import { Emitter } from 'mitt';
import { defineComponent, computed, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { mdiChevronDown } from '@mdi/js';

export default defineComponent({
  setup() {
    const router = useRouter();
    const currentRoute = ref(router.currentRoute);
    const open = ref(false);
    const emitter = inject('emitter') as Emitter;

    emitter.on('toggleSidebar', () => {
      open.value = !open.value;
    });

    emitter.on('closeSidebar', () => {
      open.value = false;
    });

    return {
      emitter,
      open,
      currentRoute,
      mdiChevronDown,
    };
  },
});
