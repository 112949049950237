
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { mdiClose } from '@mdi/js';

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentRoute = ref(router.currentRoute);
    const menu = computed(() => store.state.menustate);
    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    const logout = () => {
      store.dispatch('logout');
    };

    const user = computed(() => {
      return store.getters.accountInfo;
    });

    return {
      logout,
      user,
      menu,
      currentRoute,
      toggleMenu,
      mdiClose,
    };
  },
});
