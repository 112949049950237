
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentRoute = ref(router.currentRoute);

    /*const toggleMenu = () => {
      store.dispatch('setMenuState', !store.state.menustate);
    };*/
    const menu = computed(() => store.state.menustate);
    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    return {
      menu,
      currentRoute,
      toggleMenu,
    };
  },
});
