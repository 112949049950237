<style lang="scss">
#frontend {
  overflow-x: hidden;

  .wrapper-padding {
    padding-top: 150px;
  }
  #backToTop {
    text-align: center;
    z-index: 100;
    opacity: 0;
    /*@media(max-width:992px) {
      position:fixed;
      right:10px;
      bottom:10px;
    }*/
    position: fixed;
    display:inline-block;
    right: 0px;
    top: 98px;
    background-color: rgba($color: $blue-60per, $alpha: 1);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    @media (max-width: 992px) {
      
    }

    a {
      display: block;
      text-align: center;
      margin: 0;
      outline: none;
      padding: 10px;
      transition: all 200ms ease;
      @media(max-width:$width-md) {
        padding:5px;
      }
      svg {
        width: 25px;
        height: 25px;
      }
    }
    &:hover {
      
      a {
        background: #eee;
      }
      span {
        color: #212121;
        @media (max-width: 992px) {
          top: 0;
        }
      }
    }
  }
}
</style>

<template>
  <div id="frontend">
    <a name="top" id="top"></a>
    <div id="backToTop">
      <a href="#top" v-smooth-scroll><Icon :path="mdiChevronUp"/></a>
    </div>
    <SitebarNavigation />
    <Header />
    <TopSiteNavigation />
    <div id="pagecontent">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Header from '../components/Frontend/Header.vue';
import SitebarNavigation from '../components/Frontend/SitebarNavigation.vue';
import TopSiteNavigation from '../components/Frontend/TopSiteNavigation.vue';

import { mdiChevronUp, mdiArrowTopRight } from '@mdi/js';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    Header,
    SitebarNavigation,
    TopSiteNavigation,
  },

  setup() {
    document.addEventListener('scroll', () => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });

    const store = useStore();
    const apicall = () => {
      store.dispatch('API', { method: 'get', url: 'test' });
    };
    return {
      apicall,
      mdiChevronUp,
      mdiArrowTopRight,
    };
  },
});
</script>
