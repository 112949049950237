import { App, Plugin, VNode } from 'vue';
import store from '../store';

type MetaOptions = {
  de: MetaObject;
  en: MetaObject;
};

type MetaObject = {
  title?: string;
  description?: string;
  image?: string;
  keywords?: Array<string>;
};

const insertTag = (name: string, value: any, property?: boolean) => {
  if (property) {
    const element = document.querySelector(`meta[property='${name}']`);
    if (element != null) element.remove();

    const newelement = document.createElement('meta');
    newelement.setAttribute('property', name);
    newelement.setAttribute('content', value.toString());
    document.head.append(newelement);
  } else {
    const element = document.querySelector(`meta[name='${name}']`);
    if (element != null) element.remove();

    const newelement = document.createElement('meta');
    newelement.setAttribute('name', name);
    newelement.setAttribute('content', value.toString());
    document.head.append(newelement);
  }
};

const insertMeta = (meta: MetaOptions, locale: 'de' | 'en') => {
  insertTag('og:type', 'website', true);
  insertTag('og:url', document.location, true);
  insertTag('twitter:card', 'summary_large_image', true);
  insertTag('twitter:url', document.location, true);

  if (meta[locale].title) {
    document.title = meta[locale].title as string;
    insertTag('title', meta[locale].title);
    insertTag('og:title', meta[locale].title, true);
    insertTag('twitter:title', meta[locale].title, true);
  }

  if (meta[locale].description) {
    insertTag('description', meta[locale].description);
    insertTag('og:description', meta[locale].description, true);
    insertTag('twitter:description', meta[locale].description, true);
  }

  if (meta[locale].image) {
    const image = meta[locale].image?.startsWith('/') ? meta[locale].image?.substr(1) : meta[locale].image;
    if (image?.startsWith('http')) {
      insertTag('og:image', image, true);
      insertTag('twitter:image', image, true);
    } else {
      insertTag('og:image', `${store.getters.getHomeBase}${image}`, true);
      insertTag('twitter:image', `${store.getters.getHomeBase}${image}`, true);
    }
  }

  if (meta[locale].keywords) {
    let content = ',';
    for (const tag of meta[locale].keywords as Array<string>) {
      content = `${content}${tag},`;
    }

    insertTag('keywords', content.slice(1, -1));
  }
};

export const meta: Plugin = {
  install: (app: App) => {
    app.mixin({
      beforeCreate() {
        if (this.$options.meta) {
          const meta: MetaOptions = this.$options.meta;
          const locale: 'de' | 'en' = this.$i18n.locale || 'de';
          insertMeta(meta, locale);
        }
      },
    });

    app.provide('insertMeta', (meta: MetaOptions, locale: 'de' | 'en') => {
      insertMeta(meta, locale);
    });
  },
};
